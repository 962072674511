<h1 mat-dialog-title>{{ data.title }}</h1>

<mat-dialog-content [innerHTML]="data.message" class="mb-3"></mat-dialog-content> <!-- mat-dialog-content -->

<mat-dialog-actions align="end">
  <a mat-raised-button [color]="data.btnCancelColor" (click)="onDenial()">
    <mat-icon fontSet="fal" [fontIcon]="data.btnCancelIcon"></mat-icon>
    {{ data.btnCancelText }}
  </a>
  <button mat-raised-button [color]="data.btnOkColor" (click)="onConfirmation()" *ngIf="!messageOnly">
    <mat-icon fontSet="fal" [fontIcon]="data.btnOkIcon"></mat-icon>
    {{ data.btnOkText }}
  </button>
</mat-dialog-actions> <!-- /.mat-dialog-actions -->
