// Core packages
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Third party packages
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

// Custom packages
import { FileService } from 'src/app/shared/services/file.service';
import { HelperService } from 'src/app/shared/services/helper.service';

/**
 * Script start
 */
@Component({
  selector: 'app-add-file-dialog',
  templateUrl: './add-file-dialog.component.html',
  styleUrls: ['./add-file-dialog.component.scss'],
})
export class AddFileDialogComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  messageOnly = false;
  triggerFileUpload = false;
  triggerFileReset = false;
  allowedFilesNumber: number = 5;
  allowedFilesExtensions: string = 'jpg,jpeg,png,webp';
  fileIsUploading = false;

  constructor(
    public dialogRef: MatDialogRef<AddFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fileService: FileService,
    public helperService: HelperService,
    public toastrService: ToastrService,
  ) {
    dialogRef.disableClose = true;
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit() {}

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Handle click on Denial button
   *
   * @since 1.0.0
   */
  onDenial(): void {
    this.dialogRef.close(false);
  }

  /**
   * Handle click on confirmation button
   *
   * @since 1.0.0
   */
  onConfirmation(): void {
    this.dialogRef.close(true);
  }

  /**
   * Handle event "uploadFiles" emitted from file-uploader component
   *
   * @since 1.0.0
   *
   * @param files Files to upload
   * @returns undefined
   */
  onUploadFiles(files: NgxFileDropEntry[]): void {
    this.fileIsUploading = true;

    // You could upload it like this:
    const formData = new FormData();

    let counter = 1;
    for (const givenFile of files) {
      const fileEntry = givenFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        // const name = `file-${counter}`;
        const name = `file[]`;
        formData.append(name, file, givenFile.relativePath);
        counter++;
        if (counter === files.length + 1) {
          const uploadFilesSubscription = this.fileService
            .uploadTempFile(formData)
            .pipe(finalize(() => (this.fileIsUploading = false)))
            .subscribe(
              (res) => {
                console.log('rees: ', res);
                // Accept all-and-only last (this.to.allowedFilesNumber)
                // files (this is need! do not remove!)
                if (res.status) {
                  this.triggerFileReset = !this.triggerFileReset; // Reset drop-zone area
                  const files = res.data.slice(-this.allowedFilesNumber);
                  this.dialogRef.close(files);
                  return;
                }

                this.triggerFileReset = !this.triggerFileReset; // Reset drop-zone area
                if (res.message) {
                  this.toastrService.error('Attenzione', res.message);
                  this.fileIsUploading = false;
                  return;
                }
                this.fileIsUploading = false;
                this.toastrService.error('Errore sconosciuto');
              },
              (err: Response) => {
                // Choose one of the following error handling
                // method. The first one show a message right
                // under the form fields (if the form is properly
                // setted), the second one show toastr
                // notifications for each error
                // this.helperService.handleFormError(this.form, err);
                this.helperService.handleError(err);
              },
            );
          this.subscriptions.push(uploadFilesSubscription);
        }
      });
    }
  }
}
