// Core packages
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

// Third party packages
import { Subscription } from 'rxjs';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

// Custom packages
import { HelperService } from 'src/app/shared/services/helper.service';
import { UsersService } from '../../users.service';
import { User } from 'src/app/shared/models/user/user.model';
import Response from 'src/app/shared/interfaces/response.interface';
import { ConfigService } from 'src/app/shared/services/config.service';

/**
 * Script start
 */
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.page.html',
  styleUrls: ['./add-user.page.scss'],
})
export class AddUserComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public user: User = new User();
  // BEGIN - Form
  form: UntypedFormGroup = new UntypedFormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};
  formBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Aggiungi',
    spinnerSize: 19,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
    // buttonIcon: {
    //   fontSet: 'fal',
    //   fontIcon: 'fa-save',
    //   inline: true,
    // },
  };
  // END - Form

  constructor(
    private userService: UsersService,
    private helperService: HelperService,
    private toastrService: ToastrService,
    private configService: ConfigService,
    private router: Router,
  ) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Init form
   *
   * @since 1.0.0
   */
  initForm(): void {
    // Set fields
    this.fields = [
      ...this.fields,
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'select',
            key: 'role',
            templateOptions: {
              label: 'Ruolo',
              placeholder: 'Seleziona',
              required: true,
              type: 'string',
              options: this.configService.settings.modules.users.rolesOptions,
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'firstName',
            templateOptions: {
              label: 'Nome',
              placeholder: 'Inserisci qui...',
              required: true,
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'lastName',
            templateOptions: {
              label: 'Cognome',
              placeholder: 'Inserisci qui...',
              required: true,
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'formly-phone',
            key: 'phone',
            templateOptions: {
              prefixLabel: '+39',
              label: 'Telefono',
              placeholder: 'Inserisci qui...',
              required: true,
            },
            defaultValue: {
              prefix: '+39',
              number: '',
            },
          },

          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'email',
            templateOptions: {
              label: 'Email',
              placeholder: 'Inserisci qui...',
              required: true,
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'password',
            templateOptions: {
              label: 'Password',
              placeholder: 'Inserisci qui...',
              required: true,
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'select',
            key: 'active',
            templateOptions: {
              label: 'Attivo',
              placeholder: 'Seleziona',
              required: true,
              type: 'boolean',
              options: [
                {
                  value: false,
                  label: 'No',
                },
                {
                  value: true,
                  label: 'Si',
                },
              ],
            },
          },
        ],
      },
    ];
  }

  /**
   * Handle form submit
   *
   * @since 1.0.0
   */
  onFormSubmit(): void {
    // Prevent invalid form submit
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsTouched();
      });
      this.toastrService.error(
        'Il modulo contiene degli errori. Controlla e riprova',
      );
      return;
    }

    // Get form values
    const formValues = this.form.value;

    // Activate submit button loading status
    this.formBtnOptions = { ...this.formBtnOptions, active: true };

    // Send data to BE
    this.subscriptions.push(
      this.userService
        .create(formValues)
        .pipe(
          finalize(
            () =>
              (this.formBtnOptions = { ...this.formBtnOptions, active: false }),
          ),
        )
        .subscribe(
          (res: Response) => {
            if (!res.status) {
              const title = 'Errore';
              let message =
                res.message ||
                "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
              this.toastrService.error(message, title);
              return;
            }

            this.toastrService.success(res.message);
            this.router.navigate(['/', 'users', res?.data?._id]);
          },
          (err: Response) => {
            // Choose one of the following error handling method.
            // 1) First one show a message right under the form fields
            //    (if the form is properly setted)
            // 2) Second one is used to show a mat-error under each field
            //    in the dynamic form (is meant to be used only in dynamicForm)
            // 3) Third one show toastr notifications for each error
            // 4) Fourth one is used to show mat-error under each field
            //    in the ngx-formly form (is mean to be used only with ngx-formly)
            // this.helperService.handleFormError(form, err);
            // this.helperService.handleDynamicFormError(form, err);
            // this.helperService.handleError(err);
            this.helperService.handleFormlyError(this.form, err);
          },
        ),
    );
  }
}
