// Core packages
import { NgModule } from '@angular/core';

// Third party packages

// Custom packages
import AuthRoutingModule from './auth-routing.module';
import { LoginComponent } from './pages/login/login.page';
import { SharedModule } from '../shared.module';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.page';

/**
 * Script start
 */
@NgModule({
  imports: [AuthRoutingModule, SharedModule],
  declarations: [LoginComponent, RecoverPasswordComponent],
})
export class AuthModule {}
