<app-page>
  <div class="container-fluid">

    <div class="row">
      <div class="col-12 col-md-4">
        <app-stat-card [value]="totalUsers" descriptor="Utenti" icon="fa-users-crown" color="#EA5455" background-color="rgba(234,84,85,0.12)" ></app-stat-card>
      </div> <!-- /.col -->
      <div class="col-12 col-md-4">
        <app-stat-card descriptor="Aggiungi <br>utente" icon="fa-plus-circle" color="#42C37C" background-color="#DCEFE5" url="./users/add" ></app-stat-card>
      </div> <!-- /.col -->
    </div> <!-- /.row -->

    <div class="row">
      <div class="col-12">
        <mat-card class="mat-elevation-z shadow">
          <mat-card-content class="p-0">
            <app-table [config]="tableConfig"></app-table>
          </mat-card-content>
        </mat-card>
      </div> <!-- /.col -->
    </div> <!-- /.row -->
  </div> <!-- /.container -->
</app-page>
