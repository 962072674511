<mat-label class="custom-label" [ngClass]="{'d-none': !to.customLabel }">{{ to.customLabel }}</mat-label>
<div class="row">
  <div class="col-12">
    <app-file-uploader [upload]="triggerFileUpload" [fileReset]="triggerFileReset"
      [allowedFilesNumber]="to.allowedFilesNumber" [allowedFilesExt]="to.allowedFilesExtensions"
      (uploadFiles)="onUploadFiles($event)" [ngClass]="{'d-none': options?.formState?.disabled }">
    </app-file-uploader>
  </div>
</div>

<div class="row mt-4">
  <div class="col-12 justify-content-around">
    <div class="d-block font-italic" *ngIf="!fileIsUploading && !uploadedFiles.length">
      Nessun file in attesa di caricamento
    </div>
    <div class="d-block mt-4">
      <span *ngIf="fileIsUploading">Caricamento...</span>
    </div>
    <div class="d-block" *ngIf="uploadedFiles && uploadedFiles.length">
      <strong>
        File in attesa di caricamento:
      </strong>
    </div>
    <div *ngFor="let file of uploadedFiles">
      <div class="img-preview-box" *ngIf="file?.originalName?.split('.')?.pop()?.toLowerCase() !== 'pdf'">
        <a mat-mini-fab color="warn" class="d-none d-sm-inline-block remove-button" (click)="onRemove(file)">
          <mat-icon fontSet="fal" fontIcon="fa-trash"></mat-icon>
        </a>
        <img src="{{ file.path }}" alt="{{ file.originalName }}" (click)="onOpenImage(file)">
      </div> <!-- /.img-preview-box -->
      <div class="row" *ngIf="file?.originalName?.split('.')?.pop()?.toLowerCase() === 'pdf'">
        <div class="col-12 file-preview">
          <mat-icon class="mr-2" fontSet="fal" fontIcon="fa-file-pdf"></mat-icon>
          <div class="font-italic">{{ file.originalName }} ({{ getFormattedSize(file.size) }} KB)</div>
          <a mat-icon-button color="warn" class="d-none d-sm-inline-block" (click)="onRemove(file)">
            <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
