// Core packages
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

// Third party packages
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';

// Custom packages
import { ConfigService } from 'src/app/shared/services/config.service';
import SocketMessage from 'src/app/shared/interfaces/socketMessage.interface';
import { DashboardTableDataSource } from 'src/app/shared/components/table/dashboardTable.dataSource';

/**
 * Script start
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public data: any = {};
  public fallCounter: string = '0';
  public noSignalCounter: string = '0';
  public preFallCounter: string = '0';
  public heartBeatStatus: 'connesso' | 'disconnesso' = 'disconnesso';
  private lastHeartBeatDate!: Date;
  private heartBeatInterval: any;

  // BEGIN - Table config
  @ViewChild(MatSort) sort!: MatSort;
  public displayedColumns: string[] = ['id', 'utilizer', 'device', 'actions'];
  public selection = new SelectionModel<any>(true, []);
  public showFilters: boolean = false;
  public dataSource: DashboardTableDataSource<any> =
    new DashboardTableDataSource();
  public pageSizeOptions: number[] = [5, 10, 15];
  public expandedRow!: any;
  // END - Table config

  /**
   * Class constructor
   */
  constructor(
    private toastrService: ToastrService,
    private configService: ConfigService,
  ) {
    this.configService.initSocket();
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    // Start heartBeat interval
    const interval =
      this.configService.settings?.socket?.heartbeat?.interval || 6000;
    this.heartBeatInterval = setInterval(() => {
      const now = moment();
      const lastHeartBeat = moment(this.lastHeartBeatDate);
      const difference = moment(now).diff(lastHeartBeat, 'milliseconds');
      if (difference > interval) {
        this.heartBeatStatus = 'disconnesso';
      }
    }, interval);

    // Subscribe to socket messages
    this.subscriptions.push(
      this.configService.socketMessages$.subscribe(
        (socketMessage: SocketMessage) => {
          const { message, type, data } = socketMessage;
          switch (type) {
            case 'heatbeat':
              this.heartBeatStatus = 'connesso';
              this.lastHeartBeatDate = data.date || new Date();
              break;
            case 'update':
              this.dataSource.loadData(data);
              break;
          }
        },
      ),
    );
  }

  ngAfterViewInit(): void {}

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());

    clearInterval(this.heartBeatInterval);
  }
}
