<mat-toolbar class="app-header-wrapper" #header>
  <div class="app-header shadow">
    <div class="left-content-wrapper d-flex">
      <button mat-button color="primary" (click)="toggleSidenav()">
        <mat-icon fontSet="fal" fontIcon="fa-bars"></mat-icon>
      </button>
    </div> <!-- /.left-content-wrapper -->
  
    <div class="right-content-wrapper d-flex">
      <ng-container *ngIf="loggedUser && loggedUser._id">
        <button mat-button [matMenuTriggerFor]="userMenu">
          <div class="headerBtnContent">
            <div class="textContent">
              <strong>{{ loggedUser?.firstName }}</strong><br>
              <span class="roleLabel">{{ 'general.roles.' + loggedUser?.role | translate }}</span>
            </div>
            <div class="imageContent">
              <img src="./assets/img/user.jpg" alt="{{ loggedUser?.firstName }}">
            </div>
          </div>
          <!-- <span class="d-none d-sm-inline-block pr-1">
          </span> -->
          <!-- <mat-icon fontSet="fal" fontIcon="fa-user" class="d-sm-none"></mat-icon>
          <mat-icon fontSet="fas" fontIcon="fa-caret-down" class="d-none d-sm-inline-block"></mat-icon> -->
        </button>
        <mat-menu #userMenu="matMenu" xPosition="before">
          <button mat-menu-item (click)="onViewProfile()">Profilo</button>
          <button mat-menu-item (click)="onLogout()">Esci</button>
        </mat-menu>
      </ng-container>
    </div> <!-- /.right-content-wrapper -->
  </div> <!-- /.app-header -->
</mat-toolbar>
