// Core packages
import { DataSource } from '@angular/cdk/table';

// Third party packages
import { BehaviorSubject, Observable } from 'rxjs';

// Custom packages

/**
 * Script start
 *
 * @see https://blog.angular-university.io/angular-material-data-table/
 *
 * NB
 * If you need to change this to make a new DataSource
 * just change the following:
 *
 * - Change The class name in the first line
 * - Change DataSource<T> (use your model type) in first line
 * - Change BehaviourSubject<T> in the second line
 * - Change the service inside the class constructor
 * - Change the Observable<T> used in the connect() line
 * - Change the service name inside loadItems()
 */

export class DashboardTableDataSource<DataType>
  implements DataSource<DataType>
{
  private itemsSubject$ = new BehaviorSubject<DataType[]>([]);
  private itemsData: any[] = [];

  /**
   * Class constructor
   */
  constructor() {}

  /**
   * Connect data table to itemsSubject$
   *
   * @since 1.0.0
   *
   * @param collectionViewer collection viewer object
   * @returns Observable
   */
  connect(): Observable<DataType[]> {
    return this.itemsSubject$.asObservable();
  }

  /**
   * Disconnect data table from subjects
   *
   * @since 1.0.0
   *
   * @param collectionViewer collection viewer object
   */
  disconnect(): void {
    this.itemsSubject$.complete();
  }

  /**
   * Load given data inside itemsSubject and thus inside the table
   *
   * @since 1.0.0
   */
  loadData(data: any) {
    const newData = [];
    const {devices} = data;
    if (this.itemsData.length) {
      for (const item of this.itemsData) {
        for (const device of devices) {
          if (item?.devices.some((el: any) => el._id === device._id)) {
            // devicesAreNew = false;
            newData.push(data);
          } else {
            newData.push(item);
          }
        }
      }
    } else {
      newData.push(data);
    }
    // if (devicesAreNew) {
    //   newData.push(data);
    // } else {
    //   newData = this.itemsData.map((el: any) => {
    //     if (el?.devices.some((device: any) => device._id === data._id)) {
    //   })
    // }

    this.itemsSubject$.next(newData);
  }
}
