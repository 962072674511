// Core packages
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

// Third party packages

// Custom packages
import { LanguagesListComponent } from './pages/languages-list/languages-list.page';
import { LanguageDetailComponent } from './pages/language-detail/language-detail.page';
import { AddLanguageComponent } from './pages/add-language/add-language.page';

/**
 * Script start
 */
const routes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    component: LanguagesListComponent,
  },
  // {
  //   path: 'add',
  //   component: AddLanguageComponent,
  // },
  {
    path: ':langId',
    component: LanguageDetailComponent,
  },
  {
    path: '**',
    redirectTo: 'errors/404',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export default class LanguagesRoutingModule {}
