// Core packages
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';

// Third party packages
import moment from 'moment';
import { of, Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

// Custom packages
import { getApiUrl, getFrontEndHost } from 'src/environments/environment';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private endpoint: string;
  private httpOptions: any;

  /**
   * Class constructor
   */
  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
  ) {
    this.endpoint = getApiUrl();
    this.httpOptions = {};
  }

  /**
   * Get current values of session stored in cookie
   *
   * @since 1.0.0
   *
   * @returns object
   */
  getCookiesItems(): any {
    const sid = this.cookieService.get('s.id');
    const sexpiresAt = this.cookieService.get('s.expiresAt');
    return { sid, sexpiresAt };
  }

  /**
   * This method is a wrapper to call back-end APIs
   *
   * @since 1.0.0
   *
   * @param method HTTP Verbs to use. Can be 'GET', 'POST', 'PUT', 'DELETE'
   * @param path The endpoint relative path
   * @param [params] Optional api call params that needs to be attached to the call
   * @param extraOptions optiona extra option attached to the call
   * @returns Observable<any>
   */
  apiCall(
    method: string,
    path: string,
    params?: any,
    extraOptions?: any,
    autoRefresh?: boolean,
  ): Observable<any> {
    const autoRefreshToken =
      typeof autoRefresh === 'undefined' ? true : autoRefresh;

    if (typeof method === 'undefined') {
      return of('Missing method');
    }

    const functionName = 'apiCall' + method.toUpperCase();
    const functionToCall = (this as any)[functionName];
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const thePath = path.endsWith('/') ? path.substring(-1) : path;

    // Get session data
    // If user is already logged in, just add his token
    // to the request header
    const sessData = this.getCookiesItems();
    const sid = sessData.sid;
    const sexpiresAt = sessData.sexpiresAt;

    // Init headers
    const acceptLang = 'it';
    let headers = new HttpHeaders().set('Accept-Language', acceptLang);
    if (extraOptions && extraOptions.contentType) {
      if (extraOptions.contentType !== 'auto') {
        headers = headers.append('Content-Type', extraOptions.contentType);
      } else {
        // headers = headers.set('Content-Type', '');
        headers = headers.delete('Content-Type');
      }
      delete extraOptions.contentType; // Remove extra option after this line
    } else {
      headers = headers.append('Content-Type', 'application/json');
    }
    if (sid && moment.now() < parseInt(sexpiresAt, 10)) {
      headers = headers.append('Authorization', `Bearer ${sid}`);
    }
    if (extraOptions && extraOptions['X-Refresh-Token']) {
      headers = headers.append(
        'X-Refresh-Token',
        `Bearer ${extraOptions['X-Refresh-Token']}`,
      );
    }
    headers = headers.append('Cache-Control', `no-cache`);

    // Set httpOptions
    this.httpOptions = {
      headers,
    };

    if (typeof functionToCall === 'function') {
      return new Observable<any>((obs) => {
        const theParams = params || {};

        // Remove property with empty, undefined or null value
        // from params since they are not useful and - also -
        // since back-end could not accept undefined|null values
        // @see https://stackoverflow.com/questions/25421233/javascript-removing-undefined-fields-from-an-object
        // Object.keys(theParams).forEach(
        //   (key) => !theParams[key] && delete theParams[key],
        // );
        // Object.keys(theParams).forEach(
        //   (key) =>
        //     (theParams[key] === 'undefined' ||
        //       theParams[key] === undefined ||
        //       theParams[key] === null) &&
        //     delete theParams[key],
        // );

        // Remove 'requiredFields' from params since it's front-end-only data
        if (theParams.hasOwnProperty('requiredFields')) {
          delete theParams.requiredFields;
        }

        const obs1 = (self as any)[functionName](
          thePath,
          theParams,
          extraOptions,
        );
        obs1.subscribe(
          (res: any) => {
            // console.log('res1', res);
            return obs.next(res);
          },
          (err: any) => {
            // console.error('error1', err);
            const refreshTokenExp = +this.cookieService.get(
              's.refreshTokenExpiresAt',
            );
            // console.log('refreshTokenExp', refreshTokenExp);
            // console.log('err.status', err.status);
            // console.log(
            //   's.refreshToken',
            //   this.cookieService.get('s.refreshToken'),
            // );
            // console.log(
            //   'date check',
            //   !(moment.unix(moment.now()) > moment.unix(refreshTokenExp)),
            // );

            // If error status is 401 means that we are not logged in
            // But before logging out, let's check if a not-expired refresh-token
            // is saved in our cookies.
            // If so, let's try to re-authenticate with that refreshToken
            if (
              autoRefreshToken &&
              err.status === 401 &&
              this.cookieService.get('s.refreshToken') &&
              !(moment.unix(moment.now()) > moment.unix(refreshTokenExp))
            ) {
              // console.log('ci provo');
              const refreshToken = this.cookieService.get('s.refreshToken');
              let headers2 = new HttpHeaders().set(
                'Accept-Language',
                this.cookieService.get('lang'),
              );
              headers2 = headers2.append('Content-Type', 'application/json');
              headers2 = headers2.append(
                'X-Refresh-Token',
                `Bearer ${refreshToken}`,
              );

              return this.http
                .post<any>(
                  this.endpoint + '/auth/refresh',
                  {},
                  { headers: headers2 },
                )
                .subscribe(
                  (result) => {
                    // console.log('res2', JSON.stringify(result));

                    // BEGIN - SET AUTH
                    this.setSession(result.data);
                    // const expiresAt = moment()
                    //   .add(result.expiresIn, 'second')
                    //   .utc();
                    // this.authService.loggedUser$.next(result.user);
                    // this.authService.sessionExpiresAt$.next(
                    //   expiresAt.valueOf(),
                    // );
                    // END - SET AUTH

                    return this.apiCall(
                      method,
                      path,
                      params,
                      extraOptions,
                      false,
                    ).subscribe(
                      (res2: any) => {
                        obs.next(res2);
                      },
                      (err2: any) => {
                        // console.error('error2', err2);
                        obs.error(err2);
                      },
                      () => {
                        return obs.complete();
                      },
                    );
                  },
                  () => {
                    // console.error('error3', err);
                    obs.error(err);
                  },
                );

              // return obs.error(err);
            } else {
              // console.error('error4', err);
              return obs.error(err);
            }
          },
          () => {
            return obs.complete();
          },
        );
      });
    } else {
      console.warn('return EMPTY');
      return EMPTY;
    }
  }

  /**
   * Perform a POST API call with given path and
   * given params
   *
   * @since 1.0.0
   */
  private apiCallPOST(
    path: string,
    params?: any,
    extraOptions?: object,
  ): Observable<any> {
    const options = this.httpOptions;
    if (typeof extraOptions !== 'undefined') {
      Object.assign(options, extraOptions);
    }
    return this.http
      .post<any>(this.endpoint + '/' + path, params, options) // JSON.stringify(params)
      .pipe(catchError(this.handleError));
  }

  /**
   * Perform a PUT API call with given path and
   * given params
   *
   * @since 1.0.0
   */
  private apiCallPUT(
    path: string,
    params?: any,
    extraOptions?: object,
  ): Observable<any> {
    const options = this.httpOptions;
    if (typeof extraOptions !== 'undefined') {
      Object.assign(options, extraOptions);
    }
    return this.http
      .put(this.endpoint + '/' + path, params, options)
      .pipe(catchError(this.handleError));
  }

  /**
   * Perform a PATCH API call with given path and
   * given params
   *
   * @since 1.0.0
   */
  private apiCallPATCH(
    path: string,
    params?: any,
    extraOptions?: object,
  ): Observable<any> {
    const options = this.httpOptions;
    if (typeof extraOptions !== 'undefined') {
      Object.assign(options, extraOptions);
    }
    return this.http
      .patch(this.endpoint + '/' + path, params, options)
      .pipe(catchError(this.handleError));
  }

  private IsJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  /**
   * Perform a GET API call with given path and
   * given params
   *
   * @since 1.0.0
   */
  private apiCallGET(
    path: string,
    params?: any,
    extraOptions?: object,
  ): Observable<any> {
    const options = this.httpOptions;
    if (typeof params !== 'undefined') {
      options.params = params;
    }
    if (typeof extraOptions !== 'undefined') {
      Object.assign(options, extraOptions);
    }
    return this.http
      .get(this.endpoint + '/' + path, options)
      .pipe(catchError(this.handleError));
  }

  /**
   * Perform a DELETE API call with given path and
   * given params
   *
   * @since 1.0.0
   */
  private apiCallDELETE(
    path: string,
    params?: any,
    extraOptions?: object,
  ): Observable<any> {
    const options = this.httpOptions;
    if (typeof params !== 'undefined') {
      options.params = params;
    }
    if (typeof extraOptions !== 'undefined') {
      Object.assign(options, extraOptions);
    }
    return this.http
      .delete<any>(this.endpoint + '/' + path, options)
      .pipe(catchError(this.handleError));
  }

  /**
   * Since we are not using a type checker,
   * the response should be extracted.
   *
   * @since 1.0.0
   */
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  /**
   * Handle http errors
   *
   * @since 1.0.0
   */
  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 0) {
      return of(
        new Error('Unexpected error. Please reload the page and try again'),
      );
    }
    return throwError(error);
  }

  /**
   * Set given login result data as current session
   *
   * @since 1.0.0
   *
   * @param data Session data coming from back-end
   */
  private setSession(data: any): void {
    const expiresAt = moment().add(data.expiresIn, 'second');
    this.cookieService.set(
      's.id',
      data.token,
      undefined,
      '/',
      getFrontEndHost(),
      true,
    );
    this.cookieService.set(
      's.expiresAt',
      JSON.stringify(expiresAt.valueOf()),
      undefined,
      '/',
      getFrontEndHost(),
      true,
    );
    if (data.refreshToken) {
      this.cookieService.set(
        's.refreshToken',
        data.refreshToken,
        undefined,
        '/',
        getFrontEndHost(),
        true,
      );
    }
    if (data.refreshExpiresIn) {
      const refreshExpiresAt = moment().add(data.refreshExpiresIn, 'second');
      this.cookieService.set(
        's.refreshTokenExpiresAt',
        JSON.stringify(refreshExpiresAt.valueOf()),
        undefined,
        '/',
        getFrontEndHost(),
        true,
      );
    }
  }
}
