export const environment = {
  production: false,

  // API settings
  apiProtocol: 'https://',
  apiHost: 'api.adagio.devdomain.it',
  apiPort: '',
  apiVersion: 'v1',

  // Front-end settings
  frontEndProtocol: 'https://',
  frontEndHost: 'adagio.devdomain.it',
  frontEndPort: '',
  cookieSecure: true,
};

/**
 * Get the API URL for current environment
 *
 * @since 1.0.0
 */
export function getApiUrl(): string {
  let url = `${environment.apiProtocol}${environment.apiHost}/api/${environment.apiVersion}`;
  if (environment.apiPort) {
    url = `${environment.apiProtocol}${environment.apiHost}:${environment.apiPort}/api/${environment.apiVersion}`;
  }
  return url;
}

/**
 * Get the back-end URL for current environment
 *
 * @since 1.0.0
 */
export function getBEUrl(): string {
  let url = `${environment.apiProtocol}${environment.apiHost}`;
  if (environment.apiPort) {
    url = `${environment.apiProtocol}${environment.apiHost}:${environment.apiPort}`;
  }
  return url;
}

/**
 * Get current env front-end host
 *
 * @since 1.0.0
 */
export function getFrontEndHost(): string {
  return `${environment.frontEndHost}`;
}

/**
 * Get current config for cookie secure flat
 *
 * @since 1.0.0
 */
export function getFrontEndCookieSecure(): boolean {
  return environment.cookieSecure;
}
