// Core packages
import { NgModule } from '@angular/core';

// Third party packages

// Custom packages
import LanguagesRoutingModule from './languages-routing.module';
import { SharedModule } from '../shared.module';
import { LanguagesListComponent } from './pages/languages-list/languages-list.page';
import { LanguageDetailComponent } from './pages/language-detail/language-detail.page';
import { AddLanguageComponent } from './pages/add-language/add-language.page';

/**
 * Script start
 */
@NgModule({
  imports: [LanguagesRoutingModule, SharedModule],
  declarations: [
    LanguagesListComponent,
    LanguageDetailComponent,
    AddLanguageComponent,
  ],
})
export class LanguagesModule {}
