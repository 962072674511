<h1 mat-dialog-title>Carica qui il nuovo file</h1>

<mat-dialog-content class="mb-3">
  <div class="fileIsUploading" *ngIf="fileIsUploading">
    <mat-spinner strokeWidth="3" [diameter]="50" mode="indeterminate"></mat-spinner>
  </div>
  <app-file-uploader [upload]="triggerFileUpload" [fileReset]="triggerFileReset"
    [allowedFilesNumber]="allowedFilesNumber" [allowedFilesExt]="allowedFilesExtensions"
    (uploadFiles)="onUploadFiles($event)">
  </app-file-uploader>
</mat-dialog-content> <!-- mat-dialog-content -->

<!-- <mat-dialog-actions align=" end">
  <a mat-raised-button color="warn" (click)="onDenial()">
    <mat-icon fontSet="fal" fontIcon="fa-times"></mat-icon>
    Annulla
  </a>
  <button mat-raised-button color="primary" (click)="onConfirmation()">
    <mat-icon fontSet="fal" fontIcon="fa-check-circle"></mat-icon>
    Salva
  </button>
</mat-dialog-actions>  -->
