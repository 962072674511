// Core packages
import { Injectable } from '@angular/core';

// Third party packages
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Custom packages
import { ApiService } from 'src/app/shared/services/api.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class TranslationsService {
  /**
   * Class constructor
   */
  constructor(
    private apiService: ApiService,
    private helperService: HelperService,
  ) {}

  /**
   * Create a new translation
   *
   * @since 1.0.0
   *
   * @param translation Translation data
   * @returns Observable<any>
   */
  create(translation: any): Observable<any> {
    return this.apiService.apiCall('POST', 'translations', translation);
  }

  /**
   * Get translations list for given query params
   *
   * @since 1.0.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering object with fildering data string
   */
  getList(
    start?: number,
    limit?: number,
    sort?: string,
    sortVersus?: string,
    search?: any,
  ): Observable<ListApiResponse> {
    const params: any = { start, limit, sort, sortVersus };
    if (search) {
      Object.keys(search).forEach((key) => {
        const newParamKey = `search[${key}]`;
        const newParamValue = search[key];
        params[newParamKey] = newParamValue;
      });
    }
    return this.apiService.apiCall('GET', 'translations', params);
  }

  /**
   * Get given transId data from back-end
   *
   * @since 1.0.0
   *
   * @param transId ID of the translation to get
   */
  get(transId: string): Observable<any> {
    return this.apiService.apiCall('GET', `translations/${transId}`).pipe(
      catchError((err) => {
        this.helperService.handleError(err);
        return throwError(err);
      }),
    );
  }

  /**
   * Update translation with given transId with given user data
   *
   * @since 1.0.0
   *
   * @param transId ID of translation to update
   * @param data New translation's data
   */
  update(transId: string, data: object): Observable<any> {
    return this.apiService.apiCall('PUT', `translations/${transId}`, data);
  }

  /**
   * Update translation fields with given transId with given data
   *
   * @since 1.0.0
   *
   * @param transId ID of translation to update
   * @param data New translation's data
   */
  updateFields(transId: string, data: object): Observable<any> {
    return this.apiService.apiCall('PATCH', `translations/${transId}`, data);
  }

  /**
   * Delete permanently an translation
   *
   * @since 1.0.0
   *
   * @param transId ID of the translation
   */
  delete(transId: string): Observable<any> {
    return this.apiService.apiCall('DELETE', `translations/${transId}`);
  }
}
