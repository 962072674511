// Core packages
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Third party packages
import { BehaviorSubject } from 'rxjs';

// Custom packages
import TableConfig from 'src/app/shared/interfaces/tableConfig.interface';
import { HelperService } from 'src/app/shared/services/helper.service';
import { TranslationsService } from '../../translations.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { TableDataSource } from 'src/app/shared/components/table/table.dataSource';
import ITranslation from 'src/app/shared/models/translation/translation.interface';
import { LanguagesService } from 'src/app/modules/languages/languages.service';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';

/**
 * Script start
 */
@Component({
  selector: 'app-translations-list',
  templateUrl: './translations-list.page.html',
  styleUrls: ['./translations-list.page.scss'],
})
export class TranslationsListComponent implements OnInit {
  public tableConfig!: TableConfig<ITranslation>;
  public totalTranslations: string = '0';

  constructor(
    private translationService: TranslationsService,
    private helperService: HelperService,
    private languagesService: LanguagesService,
    private configService: ConfigService,
  ) {
    this.initTable();
    this.getCounters();
  }

  /**
   * Get counters for current page stat cards
   *
   * @since 1.0.0
   */
  async getCounters(): Promise<void> {
    try {
      this.translationService
        .getList(0, 1, 'createdAt', 'desc')
        .subscribe((res: ListApiResponse) => {
          if (res.status) {
            this.totalTranslations = res.totalCount.toString();
          }
        });
    } catch (error) {
      console.warn('Error in getCounters(): ', error);
    }
  }

  /**
   * Init table
   *
   * @since 1.0.0
   */
  initTable(): void {
    this.tableConfig = {
      dataSource: new TableDataSource(
        this.translationService,
        this.helperService,
      ),
      selectable: false,
      columns: [
        {
          key: 'section',
          label: 'Sezione',
          sortable: true,
          visible: true,
          render: (row: any, colKey: string): string => {
            const colVal = row[colKey];
            const foundItem =
              this.configService.settings?.modules?.translations?.sectionsOptions?.find(
                (el: { value: string; label: string }) => el.value === colVal,
              );
            if (foundItem) {
              return foundItem.label;
            }
            return colVal;
          },
          filter: {
            control: new UntypedFormControl(''),
            type: 'select',
            dataType: 'string',
            label: 'Seleziona',
            items:
              this.configService.settings?.modules?.translations
                ?.sectionsOptions || [],
          },
        },
        {
          key: 'confirmed',
          label: 'Confermata',
          sortable: true,
          visible: true,
          render: (row: any, colKey: string): string => {
            const colVal = row[colKey];
            if (colVal) {
              return 'Si';
            }
            return 'No';
          },
          // filter: {
          //   control: new FormControl(''),
          //   type: 'select',
          //   dataType: 'string',
          //   label: 'Seleziona',
          //   items: [
          //     {
          //       value: 'true',
          //       label: 'Si',
          //     },
          //     {
          //       value: 'false',
          //       label: 'No',
          //     },
          //   ],
          // },
        },
        {
          key: 'key',
          label: 'Chiave',
          sortable: true,
          visible: true,
          filter: {
            control: new UntypedFormControl(''),
            type: 'input',
            dataType: 'string',
            label: 'Cerca...',
          },
        },
        {
          key: 'value',
          label: 'Valore',
          sortable: true,
          visible: true,
          render: (row: any, colKey: string): string => {
            const colVal = row[colKey];
            if (colVal) {
              if (colVal.length > 20) {
                return colVal.substr(0, 20) + '...';
              }
              return colVal;
            }
            return '';
          },
          filter: {
            control: new UntypedFormControl(''),
            type: 'input',
            dataType: 'string',
            label: 'Cerca...',
          },
        },
        // {
        //   key: 'createdAt',
        //   label: 'Creazione',
        //   sortable: true,
        //   visible: true,
        //   render: (row: any, colKey: string): string => {
        //     const colVal = row[colKey];
        //     const dateIsValid = moment(colVal).isValid();
        //     if (dateIsValid && colVal) {
        //       return moment(colVal).format(this.configService.dateTimeFormat);
        //     }
        //     return colVal;
        //   },
        //   filter: {
        //     control: new FormControl(''),
        //     type: 'datepicker',
        //     dataType: 'date',
        //     label: 'Seleziona',
        //   },
        // },
      ],
      refresh$: new BehaviorSubject<boolean>(false),
      reset$: new BehaviorSubject<boolean>(false),
      rowClass: (row: ITranslation) => {
        if (!row.confirmed) {
          return 'row-warn';
        }
        return '';
      },
      options: [
        {
          title: 'Resetta filtri',
          name: '',
          icon: 'fa-broom',
          callback: () => {
            this.tableConfig.reset$.next(!this.tableConfig.reset$.value);
          },
        },
        {
          title: 'Ricarica',
          name: '',
          icon: 'fa-sync',
          callback: () => {
            this.tableConfig.refresh$.next(!this.tableConfig.refresh$.value);
          },
        },
      ],
    };
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {}
}
