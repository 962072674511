// Core packages
import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

// Third party packages
import { Subscription } from 'rxjs';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ToastrService } from 'ngx-toastr';
import { finalize, take } from 'rxjs/operators';

// Custom packages
import { HelperService } from 'src/app/shared/services/helper.service';
import { PageService } from 'src/app/shared/services/loading.service';
import { UsersService } from '../../users.service';
import { User } from 'src/app/shared/models/user/user.model';
import Response from 'src/app/shared/interfaces/response.interface';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { ConfigService } from 'src/app/shared/services/config.service';
import { AuthService } from 'src/app/modules/auth/auth.service';

/**
 * Script start
 */
@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.page.html',
  styleUrls: ['./user-detail.page.scss'],
})
export class UserDetailComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public user: User = new User();
  public itemId = this.activatedRoute.snapshot.params.userId;
  public readOnlyMode = false;

  // BEGIN - Form
  form: UntypedFormGroup = new UntypedFormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};
  formBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Salva',
    spinnerSize: 20,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
    // buttonIcon: {
    //   fontSet: 'fal',
    //   fontIcon: 'fa-save',
    //   inline: true,
    // },
  };
  // END - Form

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private pageService: PageService,
    private userService: UsersService,
    private helperService: HelperService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
    public configService: ConfigService,
    public authService: AuthService,
  ) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    this.pageService.loading$.next(true);

    this.activatedRoute.params.subscribe(async (params) => {
      const { userId } = params;
      this.itemId = userId;
      const loggedId = this.authService?.loggedUser$?.value?._id;
      const currentId = this.model._id;

      if (currentId && currentId !== loggedId) {
        console.log('AGGIORNO');
        await this.getUserData();
      }
    });

    setTimeout(() => {
      this.getUserData();
    }, 100);
  }

  async getUserData(): Promise<void> {
    this.fields = [];

    this.subscriptions.push(
      this.userService
        .get(this.itemId)
        .pipe(finalize(() => this.pageService.loading$.next(false)))
        .subscribe(
          (res: Response) => {
            if (!res.status) {
              // Something bad happened
              this.router.navigate(['users']);
            }

            // Set data
            this.user?.setData(res?.data);

            // Init main form
            this.initForm();
          },
          (err: Response) => {
            this.helperService.handleError(err);
            this.router.navigate(['errors', '404']);
          },
        ),
    );
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  capitalize = (s: string) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  /**
   * Init form
   *
   * @since 1.0.0
   */
  initForm(): void {
    // Set model
    this.model = this.user.getData();

    this.readOnlyMode =
      this.authService?.loggedUser$?.value?._id !== this.itemId &&
      this.authService?.loggedUser$?.value?.role === 'backOffice'
        ? true
        : false;

    // Set fields
    this.fields = [
      ...this.fields,
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'select',
            key: 'role',
            templateOptions: {
              label: 'Ruolo',
              placeholder: 'Seleziona',
              required: true,
              type: 'string',
              options: this.configService.settings.modules.users.rolesOptions,
              disabled:
                !['admin', 'owner'].includes(
                  this.authService.loggedUser$?.value?.role as string,
                ) || this.readOnlyMode,
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'firstName',
            templateOptions: {
              label: 'Nome',
              placeholder: 'Inserisci qui...',
              required: true,
              disabled: this.readOnlyMode,
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'lastName',
            templateOptions: {
              label: 'Cognome',
              placeholder: 'Inserisci qui...',
              required: true,
              disabled: this.readOnlyMode,
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'formly-phone',
            key: 'phone',
            templateOptions: {
              label: 'Telefono',
              placeholder: 'Inserisci qui...',
              required: false,
              disabled: this.readOnlyMode,
            },
          },

          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'email',
            templateOptions: {
              label: 'Email',
              placeholder: 'Inserisci qui...',
              required: true,
              disabled:
                !['admin', 'owner'].includes(
                  this.authService.loggedUser$?.value?.role as string,
                ) || this.readOnlyMode,
            },
          },
          {
            className: 'col-12 col-md-4',
            type: 'input',
            key: 'password',
            templateOptions: {
              label: 'Password',
              placeholder: 'Inserisci qui...',
              required: false,
              disabled: this.readOnlyMode,
            },
            hide: this.readOnlyMode,
          },
          {
            className: 'col-12 col-md-4',
            type: 'select',
            key: 'active',
            templateOptions: {
              label: 'Attivo',
              placeholder: 'Seleziona',
              type: 'boolean',
              options: [
                {
                  value: false,
                  label: 'No',
                },
                {
                  value: true,
                  label: 'Si',
                },
              ],
              disabled: this.readOnlyMode,
            },
            hideExpression: !['admin', 'owner'].includes(
              this.authService.loggedUser$?.value?.role as string,
            ),
          },
        ],
      },
    ];
  }

  /**
   * Handle form submit
   *
   * @since 1.0.0
   */
  onFormSubmit(): void {
    // Prevent invalid form submit
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsTouched();
      });
      this.toastrService.error(
        'Il modulo contiene degli errori. Controlla e riprova',
      );
      return;
    }

    // Get form values
    const formValues = this.form.value;

    // Activate submit button loading status
    this.formBtnOptions = { ...this.formBtnOptions, active: true };

    // Send data to BE
    this.subscriptions.push(
      this.userService
        .updateFields(this.user.getId() as string, formValues)
        .pipe(
          finalize(
            () =>
              (this.formBtnOptions = { ...this.formBtnOptions, active: false }),
          ),
        )
        .subscribe(
          (res: Response) => {
            if (!res.status) {
              const title = 'Errore';
              let message =
                res.message ||
                "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
              this.toastrService.error(message, title);
              return;
            }

            this.toastrService.success(res.message);
          },
          (err: Response) => {
            // Choose one of the following error handling method.
            // 1) First one show a message right under the form fields
            //    (if the form is properly setted)
            // 2) Second one is used to show a mat-error under each field
            //    in the dynamic form (is meant to be used only in dynamicForm)
            // 3) Third one show toastr notifications for each error
            // 4) Fourth one is used to show mat-error under each field
            //    in the ngx-formly form (is mean to be used only with ngx-formly)
            // this.helperService.handleFormError(form, err);
            // this.helperService.handleDynamicFormError(form, err);
            // this.helperService.handleError(err);
            this.helperService.handleFormlyError(this.form, err);
          },
        ),
    );
  }

  /**
   * Handle delete
   *
   * @since 1.0.0
   */
  onDelete(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Sei sicuro?',
        message:
          "Procedendo eliminerai l'elemento. L'operazione è irreversibile",
        btnOkText: 'Si, sono sicuro',
        btnCancelText: 'Annulla',
      },
      width: '500px',
      disableClose: true,
    });
    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (!res) {
          return;
        }

        // Start loading
        this.pageService.loading$.next(true);

        // Ok, so user really want to delete.
        // Let's do it!
        this.subscriptions.push(
          this.userService
            .delete(this.user.getId() as string)
            .pipe(finalize(() => this.pageService.loading$.next(false)))
            .subscribe(
              (res: Response) => {
                if (!res.status) {
                  const title = 'Errore';
                  let message =
                    res.message ||
                    "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
                  this.toastrService.error(message, title);
                  return;
                }

                this.toastrService.success(res.message);
                this.router.navigate(['users']);
              },
              (err: any) => {
                // Choose one of the following error handling
                // method. The first one shows a message right
                // under the form fields (if the form is properly
                // setted), the second one shows toastr
                // notifications for each error
                this.helperService.handleError(err);
              },
            ),
        );
      });
  }
}
