// Core packages
import { Component, Inject } from '@angular/core';

// Third party packages
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// Custom packages
import { ConfigService } from 'src/app/shared/services/config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lightbox-dialog',
  templateUrl: './lightbox-dialog.component.html',
  styleUrls: ['./lightbox-dialog.component.scss'],
})
export class LightboxDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<LightboxDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public configService: ConfigService,
  ) {}

  /**
   * Handle dialog close
   */
  onClose(): void {
    this.dialogRef.close();
  }

  getDataUrl(): string {
    if (this?.data?.url?.includes(environment.apiHost)) {
      return this.data.url;
    }

    return environment.apiHost + this?.data?.url;
  }
}
