// Core packages

// Third party packages
import { Component, OnInit, Input } from '@angular/core';

// Custom packages

/**
 * Script start
 */
@Component({
  selector: 'app-creator-updator-box',
  templateUrl: './creator-updator-box.component.html',
  styleUrls: ['./creator-updator-box.component.scss'],
})
export class CreatorUpdatorBoxComponent implements OnInit {
  @Input() data: any;

  constructor() {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {}
}
