// Core packages
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

// Third party packages

// Custom packages
import { LoginComponent } from './pages/login/login.page';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.page';

/**
 * Script start
 */
const routes: Route[] = [
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent,
  },
  {
    path: 'recover-password',
    pathMatch: 'full',
    component: RecoverPasswordComponent,
  },
  {
    path: '**',
    redirectTo: 'errors/404',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export default class AuthRoutingModule {}
