// Core packages

// Third party packages

// Custom packages
import { BaseModel } from '../_base/model.class';

/**
 * Script start
 */
export class Translation extends BaseModel {
  constructor(
    private _id?: string,
    private lang?: string,
    private key?: string,
    private value?: string,
    private section?: string,
    private confirmed?: string,
    private createdAt?: Date,
    private createdBy?: string,
    private updatedAt?: Date,
    private updatedBy?: string,
  ) {
    super();

    this.setRequiredFields([
      '_id',
      'lang',
      'key',
      'value',
      'section',
      'confirmed',
      'createdAt',
      'createdBy',
    ]);
  }
}
