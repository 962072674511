// Core components
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

// Third party components
import { FieldType } from '@ngx-formly/material';

// Custom components

/**
 * Script start
 */
@Component({
  selector: 'app-formly-textarea-with-counter-type',
  template: `
    <mat-form-field class="d-block" [ngClass]="this?.to?.className">
      <mat-label *ngIf="this.to.label">{{ this.to.label }}</mat-label>
      <textarea
        [(ngModel)]="this.value"
        matInput
        [rows]="this.to.rows"
        [maxlength]="maxLength"
        [required]="this.to.required || false"
        >{{ this.value }}</textarea
      >
      <mat-hint align="start" *ngIf="this.to.description">{{ this.to.description }}</mat-hint>
      <mat-hint align="end"
        >{{ this?.value?.length }} / {{ maxLength }}</mat-hint
      >
    </mat-form-field>
  `,
})
export class TextareaWithCounterTypeComponent
  extends FieldType
  implements OnInit, AfterViewInit, OnDestroy {
  public maxLength!: number;

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.maxLength = this.to.maxLength as number;
  }

  /**
   * Handle afterViewInit of this component
   *
   * @since 1.0.0
   */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  /**
   * Handle ngOnDestroy of this component
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
