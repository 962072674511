// Core packages

// Third party packages

// Custom packages

/**
 * Script start
 */
export class BaseModel {
  private requiredFields: string[] = [];

  /**
   * Check if current class instance has all required fields
   *
   * @since 1.0.0
   */
  isValid(): boolean {
    for (const key of this.requiredFields) {
      if (this.get(key) === undefined || this.get(key) === null) {
        console.warn('undefined or null', key);
        return false;
      }
    }
    return true;
  }

  /**
   * Set given value for property with given key inside current model instance
   *
   * @todo: support dot notation like in the getter
   *
   * @since 1.0.0
   */
  set(key: string, value: any): boolean {
    if (!this.hasOwnProperty(key)) {
      return false;
    }
    (this as any)[key] = value;
    return true;
  }

  /**
   * Get property with given key in current model instance
   * NB: supports dot notation like get('user.address.city');
   *
   * @since 1.0.0
   */
  get(key: string): any {
    return key
      .split('.')
      .reduce((a: any, b: any) => (a ? a[b] : undefined), this);
  }

  /**
   * Get model id
   *
   * @since 1.0.0
   */
  getId(): string | undefined {
    return this.get('_id');
  }

  /**
   * Get all private properties data and return them as object
   *
   * @since 1.0.0
   */
  getData(): any {
    const data: any = {};
    Object.keys(this).forEach((key: string) => {
      data[key] = this.get(key);
    });
    return data;
  }

  /**
   * Set given data to all private properties (if match)
   * and returns true if everything goes right, false
   * otherwise
   *
   * @since 1.0.0
   */
  setData(data: any): undefined | any {
    Object.keys(data).forEach((key: string) => {
      if (this.hasOwnProperty(key)) {
        const value = data[key];
        this.set(key, value);
      } else {
        console.warn(`Property ${key} does not exists`);
      }
      return undefined;
    });
    return this.getData();
  }

  /**
   * Get the list of required fields
   *
   * @since 1.0.0
   */
  getRequiredFields(): string[] {
    return this.requiredFields;
  }

  /**
   * Set given data as required fields
   *
   * @since 1.0.0
   */
  setRequiredFields(data: string[]): void {
    this.requiredFields = data;
  }
}
