// Core packages
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Third party packages
import { BehaviorSubject } from 'rxjs';

// Custom packages
import TableConfig from 'src/app/shared/interfaces/tableConfig.interface';
import { HelperService } from 'src/app/shared/services/helper.service';
import { UsersService } from '../../users.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { TableDataSource } from 'src/app/shared/components/table/table.dataSource';
import IUser from 'src/app/shared/models/user/user.interface';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';

/**
 * Script start
 */
@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.page.html',
  styleUrls: ['./users-list.page.scss'],
})
export class UsersListComponent implements OnInit {
  public tableConfig!: TableConfig<IUser>;
  public totalUsers: string = '0';

  constructor(
    private userService: UsersService,
    private helperService: HelperService,
    private configService: ConfigService,
  ) {
    this.initTable();
    this.getCounters();
  }

  /**
   * Get counters for current page stat cards
   *
   * @since 1.0.0
   */
  async getCounters(): Promise<void> {
    try {
      this.userService
        .getList(0, 1, 'createdAt', 'desc')
        .subscribe((res: ListApiResponse) => {
          if (res.status) {
            this.totalUsers = res.totalCount.toString();
          }
        });
    } catch (error) {
      console.warn('Error in getCounters(): ', error);
    }
  }

  /**
   * Init table
   *
   * @since 1.0.0
   */
  initTable(): void {
    this.tableConfig = {
      dataSource: new TableDataSource(this.userService, this.helperService),
      selectable: false,
      columns: [
        {
          key: 'email',
          label: 'Email',
          sortable: true,
          visible: true,
          filter: {
            control: new UntypedFormControl(''),
            type: 'input',
            dataType: 'string',
            label: 'Cerca...',
          },
        },
        {
          key: 'role',
          label: 'Ruolo',
          sortable: false, // NB: it's a nonsense sorting by this field since we are using a key (instead of the label) inside the database
          visible: true,
          render: (row: any, key: string): string => {
            const colVal = row[key];
            const foundItem =
              this.configService.settings?.modules?.users?.rolesOptions?.find(
                (el: { value: string; label: string }) => el.value === colVal,
              );
            if (foundItem) {
              return foundItem.label;
            }
            return colVal;
          },
          filter: {
            control: new UntypedFormControl(''),
            type: 'select',
            dataType: 'string',
            label: 'Seleziona',
            items:
              this.configService.settings?.modules?.users?.rolesOptions || [],
          },
        },
        {
          key: 'firstName',
          label: 'Nome',
          sortable: true,
          visible: true,
          filter: {
            control: new UntypedFormControl(''),
            type: 'input',
            dataType: 'string',
            label: 'Cerca...',
          },
        },
        {
          key: 'lastName',
          label: 'Cognome',
          sortable: true,
          visible: true,
          filter: {
            control: new UntypedFormControl(''),
            type: 'input',
            dataType: 'string',
            label: 'Cerca...',
          },
        },
      ],
      refresh$: new BehaviorSubject<boolean>(false),
      reset$: new BehaviorSubject<boolean>(false),
      options: [
        {
          title: 'Resetta filtri',
          name: '',
          icon: 'fa-broom',
          callback: () => {
            this.tableConfig.reset$.next(!this.tableConfig.reset$.value);
          },
        },
        {
          title: 'Ricarica',
          name: '',
          icon: 'fa-sync',
          callback: () => {
            this.tableConfig.refresh$.next(!this.tableConfig.refresh$.value);
          },
        },
      ],
    };
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {}

  /**
   * Refresh table
   *
   * @since 1.0.0
   */
  refresh(): void {
    this.tableConfig.refresh$.next(!this.tableConfig.refresh$.value);
  }
}
