// Core packages
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

// Third party packages

// Custom packages
import { TranslationsListComponent } from './pages/translations-list/translations-list.page';
import { TranslationDetailComponent } from './pages/translation-detail/translation-detail.page';
import { AddTranslationComponent } from './pages/add-translation/add-translation.page';

/**
 * Script start
 */
const routes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    component: TranslationsListComponent,
  },
  {
    path: 'add',
    component: AddTranslationComponent,
  },
  {
    path: ':transId',
    component: TranslationDetailComponent,
  },
  {
    path: '**',
    redirectTo: 'errors/404',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export default class TranslationsRoutingModule {}
