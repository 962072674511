// Core packages
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

// Third party packages
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';

// Custom packages

/**
 * Script start
 */
@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit, OnChanges {
  files: NgxFileDropEntry[] = [];
  hasFile = false;
  // @see here for EXCEL and CSV files
  // https://stackoverflow.com/questions/11832930/html-input-file-accept-attribute-file-type-csv
  // https://www.w3schools.com/tags/att_input_accept.asp
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file

  @Input() isUploading: boolean = false;
  @Input() upload!: boolean;
  @Input() fileReset!: boolean;
  @Input() allowedFilesNumber!: number; // Max number of admitted files
  @Input() allowedFilesExt!: string; // Allowed files extensions comma separated (es: jpeg,png,jpg,gif)
  @Input() label!: string;
  @Output() uploadFiles = new EventEmitter(); // Callback used to actually upload files to the back-end
  allowedExtensionsArray: string[] = [];
  allowedFilesExtWithDot: string = '';

  /**
   * Class constructor
   */
  constructor(private toastrService: ToastrService) {}

  /**
   * Init component
   */
  ngOnInit(): void {
    this.allowedExtensionsArray = this.allowedFilesExt
      ? this.allowedFilesExt.toLowerCase().split(',')
      : ['*'];
    this.allowedFilesExtWithDot = `.${this.allowedExtensionsArray.join(',.')}`;
  }

  /**
   * Listen for @Input changes and trigger the uploadFile @Output
   *
   * @since 1.0.0
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.upload && typeof changes.upload.previousValue !== 'undefined') {
      this.onUploadFiles();
    }
    if (
      changes.fileReset &&
      typeof changes.fileReset.previousValue !== 'undefined'
    ) {
      this.files = [];
      this.hasFile = false;
    }
  }

  /**
   * Emit a new value for the uploadFiles @Output with
   * a list of loaded files
   *
   * @since 1.0.0
   */
  onUploadFiles(): void {
    const maxNumber = this.allowedFilesNumber ? this.allowedFilesNumber : 1;
    if (!this.files.length) {
      return;
    }
    if (this.files.length > maxNumber) {
      // @todo translate
      this.toastrService.error(
        `Puoi caricare massimo ${maxNumber} file alla volta`,
      );
      return;
    }
    this.uploadFiles.emit(this.files);
  }

  /**
   * Handle dropped file once they are released on the dropzone area
   *
   * @since 1.0.0
   *
   * @param files uploaded files
   */
  dropped(files: NgxFileDropEntry[]): void {
    const toastrServiceReplica = this.toastrService;
    for (const droppedFile of files) {
      // Is it a file
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Check if file type is allowed here
          const fileName = file.name;
          const extension = fileName?.split('.')?.pop()?.toLowerCase() || '';
          if (
            this.allowedExtensionsArray.includes(extension) ||
            this.allowedExtensionsArray.includes('*')
          ) {
            const newFile = {
              ...droppedFile,
              size: file.size,
              type: file.type.replace('/', '-'),
            };
            this.files.push(newFile); // Push file
            this.hasFile = true; // Inform component that there is at least one file
          } else {
            const title = 'Errore';
            const message = `Il formato del file '${droppedFile.relativePath}' non è tra quelli consentiti`;
            toastrServiceReplica.error(message, title);
          }
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        // const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        const message =
          'Non puoi caricare direttamente una cartella. Seleziona un file';
        alert(message);
      }
    }

    // Automatically upload on file choose.
    // Wait 500ms in order to get files pushing finished
    setTimeout(() => {
      this.onUploadFiles();
    }, 500);
  }

  /**
   * Transform given size in bytes to
   * a kb string
   *
   * @since 1.0.0
   *
   * @param size The size to transform
   * @return Transformed size string with 2 decimals
   */
  getFormattedSize(size: number): string {
    const sizeInKb = size / 1024;
    return sizeInKb.toFixed(2);
  }

  /**
   * Remove file with given index from upload files list
   *
   * @since 1.0.0
   *
   * @param index The index of the file to remove
   * @retuens undefined
   */
  onFileRemove(index: number): void {
    this.files.splice(index, 1);
    if (this.files.length === 0) {
      this.hasFile = false;
    }
  }

  fileOver(event: any) {}

  fileLeave(event: any) {}
}
