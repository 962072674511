// Core packages
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

// Third party packages
import { FieldType } from '@ngx-formly/material';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Custom packages
import IPhone from 'src/app/shared/interfaces/phone.interface';
import phonePrefixes from 'src/assets/json/phonePrefix.json';

/**
 * Script start
 */

@Component({
  selector: 'app-formly-phone-type',
  templateUrl: './phone-type.component.html',
  styleUrls: ['./phone-type.component.scss'],
})
export class MaterialPhoneTypeComponent
  extends FieldType
  implements OnInit, AfterViewInit, OnDestroy
{
  public prefixFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredPrefixes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  parts: UntypedFormGroup;
  showDash = false;
  public prefixes = phonePrefixes
    .map((el: any) => ({
      label: el.dial_code ? `${el.dial_code} (${el.code})` : '',
      value: el.dial_code,
    }))
    .sort((prev: any, next: any) => {
      if (prev.value > next.value) {
        return 1;
      }
      return -1;
    });
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(private fb: UntypedFormBuilder) {
    super();

    this.parts = fb.group({
      prefix: '',
      number: '',
    });
  }

  get value2(): IPhone | null {
    let n = this.parts.value;
    if (n.prefix.length == 3 && n.number.length >= 10) {
      const phoneNumber: IPhone = {
        prefix: n.prefix,
        number: n.number,
      };
      return phoneNumber;
    }
    return null;
  }

  set value2(tel: IPhone | null) {
    tel = tel || {
      prefix: '',
      number: '',
    };
    this.parts.setValue({
      prefix: tel.prefix,
      number: tel.number,
    });
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    super.ngOnInit();

    // Lister for filter changes
    this.prefixFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((obs) => {
        this.filterPrefixes();
      });

    // Listen for custom imputs changes and update formly field value
    this.parts.valueChanges.subscribe((obs) => {
      this.value = obs;
    });

    // Take value from formly field and fill custom inputs
    if (this.value && (this.value.number || this.value.prefix)) {
      this.parts.setValue({
        prefix: this.value.prefix,
        number: this.value.number,
      });
      this.prefixFilterCtrl.setValue(this.value?.prefix);
      // this.prefixFilterCtrl = this.value.prefix;
      this.showDash = true;
    } else {
      // console.log('this', this);
    }

    // Disable fields if required
    if (this.to.disabled) {
      this.parts.disable();
    }
  }

  /**
   * Handle afterViewInit of this component
   *
   * @since 1.0.0
   */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  /**
   * Handle ngOnDestroy of this component
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    super.ngOnDestroy();
  }

  onFocus(element: any, focusType: string): void {
    if (this.value && (this.value.number || this.value.prefix)) {
      return;
    }
    if (focusType === 'in') {
      this.showDash = true;
    } else {
      this.showDash = false;
    }
  }

  protected filterPrefixes() {
    if (!this.prefixes) {
      return;
    }
    // get the search keyword
    let search = this.prefixFilterCtrl.value;
    if (!search) {
      this.filteredPrefixes.next(this.prefixes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the prefixes
    this.filteredPrefixes.next(
      this.prefixes.filter(
        (prefix) => prefix.value.toLowerCase().indexOf(search) > -1,
      ),
    );
  }
}
