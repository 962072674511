// Core packages
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// Third party packages

// Custom packages
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

/**
 * Script start
 */

// Print a console log with dev logo
function logImage(url: string, scale = 0.5): void {
  // Create an Image instance
  const image = new Image();

  image.onload = () => {
    // Inside here we already have image dimensions
    const style = `
      display: block !important;
      margin: 0;
      margin-left: 10px;
      font-size: ${image.height * scale}px;
      padding: ${Math.floor((image.height * scale) / 2)}px ${Math.floor(
      (image.width * scale) / 2,
    )}px;
      background: url(${url}) no-repeat;
      background-size: ${image.width * scale}px ${image.height * scale}px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    `;
    console.log(
      '%c Powered by',
      'color:#1865AE;font-weight: bold;font-size: 26px',
    );
    console.log('%c ', style);
    console.log(
      '%c View more on www.sitisrl.it',
      'color:#1865AE;font-weight: bold;font-size: 20px',
    );
  };

  // Actyally load image
  image.src = url;
}
(console as any).image = logImage;
// Define image url
const url = 'https://sitisrl.it/assets/img/logo/logo192.png';
const scale = 0.5;
(console as any).image(url, scale);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
