// Core packages
import { Component } from '@angular/core';

// Third party packages
import { TranslateService } from '@ngx-translate/core';

// Custom packages
import { ValidationLoaderService } from './shared/services/validations-loader.service';
import { ConfigService } from './shared/services/config.service';

/**
 * Script start
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'adagio-front-end';

  constructor(
    private validationLoaderService: ValidationLoaderService,
    private configService: ConfigService,
    private translateService: TranslateService,
  ) {
    this.validationLoaderService.init();

    const settings = this.configService.settings;

    this.translateService.setDefaultLang(settings.i18n.defaultLang);
    this.translateService.use(settings.i18n.defaultLang);
  }
}
