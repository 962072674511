// Core packages
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

// Third party packages
import { Subscription } from 'rxjs';

// Custom packages
import { ConfigService } from 'src/app/shared/services/config.service';

/**
 * Script start
 */
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  /**
   * Class constructor
   */
  constructor(public configService: ConfigService) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {}

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  getModules(): string {
    const modules = Object.keys(this.configService?.settings?.modules).join(
      ', ',
    );
    return modules;
  }
}
