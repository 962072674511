<ng-container>
  <ngx-file-drop dropZoneLabel="Trascina qui i file" [accept]="allowedFilesExtWithDot"
  [multiple]="allowedFilesNumber > 1" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)"
  (onFileLeave)="fileLeave($event)" [showBrowseBtn]="true" class="import-area">
  <!-- <ngx-file-drop dropZoneLabel="Trascina qui i file" [accept]="allowedFilesExtWithDot"
    [multiple]="allowedFilesNumber > 1" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)"
    (onFileLeave)="fileLeave($event)" [showBrowseBtn]="true" class="import-area" [ngClass]="{'has-file': hasFile }"> -->
    
    
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <!-- <app-card-spinner [visible]="hasFile" type="zero"></app-card-spinner> -->
      <mat-spinner *ngIf="isUploading" color="primary" class="loader"></mat-spinner>
      <div *ngIf="!isUploading" class="hint-wrapper d-flex align-items-center" (click)="openFileSelector()">
        <div class="hint">
          Trascina qui i file o caricane uno
        </div> <!-- /.hint -->
        <button class="ml-1" type="button" mat-icon-button color="primary">
          <!-- {{ 'common.fileUploader.selectButton' | translate }} -->
          <mat-icon fontSet="fal" fontIcon="fa-upload"></mat-icon>
        </button>
      </div> <!-- /.hint-wrapper -->
      <!-- <div class="file-preview" *ngFor="let item of files; let i=index" [ngClass]="item.type">
        <span class="name">{{ item.relativePath }}</span>
        <span class="size">{{ getFormattedSize(item.size) }} Kb</span>
        <button mat-mini-fab color="warn" class="remove-button" (click)="onFileRemove(i)">
          <mat-icon fontSet="fal" fontIcon="fa-trash"></mat-icon>
        </button>
        <mat-icon fontSet="fal" fontIcon="fa-file" class="file-icon"></mat-icon>
      </div> -->
    </ng-template>
  </ngx-file-drop>
  <small>Estensioni consentite: {{ allowedFilesExt }}</small>
</ng-container>
