// Core packages
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

// Third party packages

// Custom packages
import { UserDetailComponent } from './pages/user-detail/user-detail.page';
import { AddUserComponent } from './pages/add-user/add-user.page';
import { UsersListComponent } from './pages/users-list/users-list.page';
import { RoleGuard } from 'src/app/shared/guards/role.guard';

/**
 * Script start
 */
const routes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    component: UsersListComponent,
    canActivate: [RoleGuard],
    data: {
      permittedRoles: ['admin', 'owner', 'backOffice'],
    },
  },
  {
    path: 'add',
    component: AddUserComponent,
    canActivate: [RoleGuard],
    data: {
      permittedRoles: ['admin', 'owner', 'backOffice'],
    },
  },
  {
    path: ':userId',
    component: UserDetailComponent,
  },
  {
    path: '**',
    redirectTo: 'errors/404',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export default class UsersRoutingModule {}
