// Core packages
import { NgModule } from '@angular/core';

// Third party packages

// Custom packages
import TranslationsRoutingModule from './translations-routing.module';
import { SharedModule } from '../shared.module';
import { TranslationsListComponent } from './pages/translations-list/translations-list.page';
import { TranslationDetailComponent } from './pages/translation-detail/translation-detail.page';
import { AddTranslationComponent } from './pages/add-translation/add-translation.page';

/**
 * Script start
 */
@NgModule({
  imports: [TranslationsRoutingModule, SharedModule],
  declarations: [
    TranslationsListComponent,
    TranslationDetailComponent,
    AddTranslationComponent,
  ],
})
export class TranslationsModule {}
