// Core packages
import { Component, OnInit, Inject } from '@angular/core';

// Third party packages
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Custom packages

/**
 * Script start
 */
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  messageOnly = false;
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit() {
    // Set default icons if none are provided
    if (!this.data.btnOkIcon) {
      this.data.btnOkIcon = 'fa-check-circle';
    }
    if (!this.data.btnCancelIcon) {
      this.data.btnCancelIcon = 'fa-times-circle';
    }

    // Set default button colors if none are provided
    if (!this.data.btnOkColor) {
      this.data.btnOkColor = 'primary';
    }
    if (!this.data.btnCancelColor) {
      this.data.btnCancelColor = 'warn';
    }

    if (this.data.messageOnly) {
      this.messageOnly = this.data.messageOnly;
    }
  }

  /**
   * Handle click on Denial button
   *
   * @since 1.0.0
   */
  onDenial(): void {
    this.dialogRef.close(false);
  }

  /**
   * Handle click on confirmation button
   *
   * @since 1.0.0
   */
  onConfirmation(): void {
    this.dialogRef.close(true);
  }
}
