<div class="page auth-page">
  <div class="left">
    <div class="col-content">
      <img src="/assets/img/logo/logo-100.png" alt="{{ 'project.name' | translate }}" class="logo" width="250">
      <img src="/assets/img/auth.png" alt="{{ 'project.name' | translate }}">
    </div>
  </div>
  <div class="right">
    <div class="col-content">
      <img src="/assets/img/logo/logo-100.png" alt="{{ 'project.name' | translate }}" class="logo" width="250">
      <h1 class="h4">{{ 'pages.login.title' | translate }}</h1>
      <div class="form-wrapper">
        <form [formGroup]="form" (ngSubmit)="onFormSubmit(model)" class="mb-3">
          <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
          <mat-spinner-button role="submit" [options]="formBtnOptions" class="btn-elevate">
          </mat-spinner-button>
        </form>
        <p class="text-right"><a routerLink="../recover-password">{{ 'pages.login.recoverPassword' |
          translate }}</a></p>
      </div> <!-- /.form-wrapper -->
    </div> <!-- /.col-content -->
  </div>

</div> <!-- /.page -->
