<div *ngIf="data && data.createdAt" class="container-fluid creator-updator-box">
  <div class="row">
    <div class="col-12 col-lg-6">
      Creato da <a routerLink="/users/{{ data?.createdBy?._id }}">{{ data?.createdBy?.firstName }}
        {{ data?.createdBy?.lastName }}</a> il {{ data.createdAt | date:'dd LLL yyyy HH:mm' }}
    </div>
    <div class="col-12 col-lg-6" *ngIf="data.updatedAt && data.updatedBy && data.updatedBy._id">
      Aggiornato da <a routerLink="/users/{{ data?.updatedBy?._id }}">{{ data?.updatedBy?.firstName }}
        {{ data?.updatedBy?.lastName }}</a> il {{ data?.updatedAt | date:'dd LLL yyyy HH:mm' }}
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-12 col-md-6">
      <mat-list dense class="left-aligned-mat-list">
        <mat-list-item *ngIf="data && data.createdAt">
          <strong class="pr-2">Creata il:</strong>
          {{ data.createdAt | date:'dd LLL yyyy HH:mm' }}
        </mat-list-item>
        <mat-list-item *ngIf="data && data.createdBy">
          <strong class="pr-2">Crata da:</strong>
          <a routerLink="/users/{{ data.createdBy._id }}">{{ data.createdBy.firstName }}
            {{ data.createdBy.lastName }}</a>
        </mat-list-item>
      </mat-list>
    </div>
    <div class="col-12 col-md-6">
      <mat-list dense class="left-aligned-mat-list">
        <mat-list-item *ngIf="data && data.updatedAt">
          <strong class="pr-2">Aggiornata il:</strong>
          {{ data.updatedAt | date:'dd LLL yyyy HH:mm' }}
        </mat-list-item>
        <mat-list-item *ngIf="data && data.updatedBy">
          <strong class="pr-2">Aggiornato da:</strong>
          <a routerLink="/users/{{ data.updatedBy._id }}">{{ data.updatedBy.firstName }}
            {{ data.updatedBy.lastName }}</a>
        </mat-list-item>
      </mat-list>
    </div>
  </div> -->
</div> <!-- /.container -->
