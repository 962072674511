<app-page>
  <div class="container-fluid">

    <div class="row">
      <div class="col-12 col-md-4">
        <app-stat-card [value]="fallCounter" descriptor="Cadute" icon="fa-bell-on" color="#EA5455"
          background-color="rgba(234,84,85,0.12)"></app-stat-card>
      </div> <!-- /.col -->
      <div class="col-12 col-md-4">
        <app-stat-card [value]="noSignalCounter" descriptor="No segnale" icon="fa-wifi" color="#7367F0"
          background-color="rgba(115,103,240,0.12)"></app-stat-card>
      </div> <!-- /.col -->
      <div class="col-12 col-md-4">
        <app-stat-card [value]="preFallCounter" descriptor="Pre cadute" icon="fa-bell-exclamation" color="#FF9F43"
          background-color="rgba(255,159,67,0.12)"></app-stat-card>
      </div> <!-- /.col -->
    </div> <!-- /.row -->

    <div class="row">
      <div class="col-12">
        <mat-card class="mat-elevation-z shadow">
          <mat-card-content class="p-0">

            <div class="table-wrapper">
              <div class="table-responsive">
                <table mat-table [dataSource]="dataSource" matSort matSortActive="id" matSortDirection="desc"
                  matSortDisableClear multiTemplateDataRows>

                  <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Codice</th>
                    <ng-container>
                      <td mat-cell *matCellDef="let row">
                        <div>{{ row.id }}</div>
                      </td>
                    </ng-container>
                  </ng-container>

                  <ng-container matColumnDef="utilizer">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome intero</th>
                    <ng-container>
                      <td mat-cell *matCellDef="let row">
                        <div>
                          <strong>{{ row.utilizer?.firstName }} {{ row.utilizer?.lastName }}</strong><br>
                          <small>{{ row.utilizer?.address?.route }}, {{ row.utilizer?.address?.streetNumber }} - {{
                            row.utilizer?.address?.postalCode }} {{ row.utilizer?.address?.city }}</small>
                        </div>
                      </td>
                    </ng-container>
                  </ng-container>

                  <ng-container matColumnDef="device">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Dispositivo</th>
                    <ng-container>
                      <td mat-cell *matCellDef="let row">
                        <div>
                          {{ row.devices?.length }}
                        </div>
                      </td>
                    </ng-container>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Azioni</th>
                    <td mat-cell *matCellDef="let row" class="text-right">
                      <button mat-button>
                        <mat-icon fontSet="fal" fontIcon="fa-ellipsis-v-alt"></mat-icon>
                      </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                  </ng-container>

                  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                  <ng-container matColumnDef="expandedDetail">
                    <th mat-header-cell *matHeaderCellDef>Dettagli</th>
                    <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
                      <div class="devices-list-wrapper" [@detailExpand]="row == expandedRow ? 'expanded' : 'collapsed'">
                        <div class="devices-list">
                          <ng-container *ngFor="let device of row.devices">
                            <div class="row device-row">
                              <div class="col-md-4 d-flex align-items-center">
                                <ng-container *ngIf="device.room === 'kitchen'">
                                  <mat-icon fontSet="fal" fontIcon="fa-knife-kitchen" class="fa-1x"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="device.room === 'bathRoom'">
                                  <mat-icon fontSet="fal" fontIcon="fa-bath" class="fa-1x"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="device.room === 'bedRoom'">
                                  <mat-icon fontSet="fal" fontIcon="fa-bed" class="fa-1x"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="device.room === 'livingRoom'">
                                  <mat-icon fontSet="fal" fontIcon="fa-tv" class="fa-1x"></mat-icon>
                                </ng-container>
                                <span class="px-2">
                                  {{ 'general.installations.rooms.' + device.room | translate }}
                                </span>
                              </div> <!-- /.col -->
                              <div class="col-md-4">
                                <mat-chip-list class="mat-chip-list-stacked">
                                  <mat-chip color="warn" selected style="width:90px; justify-content: center;">Caduta
                                  </mat-chip>
                                </mat-chip-list>
                              </div> <!-- /.col -->
                              <div class="col-md-4">

                              </div> <!-- /.col -->
                            </div> <!-- /.row -->
                          </ng-container>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <!--
                  <tr mat-row *matRowDef="let row; columns: displayedColumns">
                  </tr> -->

                  <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="element-row"
                    [class.expanded-row]="expandedRow === element"
                    (click)="expandedRow = expandedRow === element ? null : element">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

                  <!-- Row shown when there is no matching data. -->
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length + 1">Nessun dato presente
                    </td>
                  </tr>
                </table>
              </div> <!-- /.table-responsive -->

            </div> <!-- /.table-wrapper -->

            <div class="px-2 py-3">
              Stato: {{ heartBeatStatus }}
            </div>
          </mat-card-content>
        </mat-card>
      </div> <!-- /.col -->
    </div> <!-- /.row -->

  </div> <!-- /.container -->
</app-page>
