// Core packages
import { NgModule } from '@angular/core';

// Third party packages

// Custom packages
import UserRoutingModule from './users-routing.module';
import { UsersListComponent } from './pages/users-list/users-list.page';
import { SharedModule } from '../shared.module';
import { UserDetailComponent } from './pages/user-detail/user-detail.page';
import { AddUserComponent } from './pages/add-user/add-user.page';

/**
 * Script start
 */
@NgModule({
  imports: [UserRoutingModule, SharedModule],
  declarations: [
    UsersListComponent,
    UserDetailComponent,
    AddUserComponent,
  ],
})
export class UsersModule {}
