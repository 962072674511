// Core pakages
import { Inject, Injectable, Injector } from '@angular/core';

// Third party packages
import { io, Socket } from 'socket.io-client';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

// Custom packages
import { ApiService } from './api.service';
import SocketMessage from '../interfaces/socketMessage.interface';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  // Config settings coming from BE
  private configSettings: any = null;
  private socket: Socket<any, any> | null = null;

  // General config
  redirectDelayAfterCreation = 1000;

  // Pattern for password validation
  // tslint:disable-next-line:max-line-length
  readonly passwordPattern: RegExp = new RegExp(/^(\w+\d+)$/);

  // Pattern for email validation
  // tslint:disable-next-line:max-line-length
  readonly emailPattern: RegExp = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  );

  // Pattern for phone number validation
  // tslint:disable-next-line:max-line-length
  readonly phonePattern: RegExp = new RegExp(
    /^((((\d){2,4}|((\d){3}-(\d){1}))(\s)?(\d){6,7})|([+39]{3}(\s)?((\d){2,4}|((\d){3}-(\d){1}))(\d){6,7})|([+39]{3}(\s)?((\d){2,4}|((\d){3}-(\d){1}))(\s)?(\d){6,7}))$/,
    'm',
  );

  // Pattern for webSiteUrl validation
  // tslint:disable-next-line:max-line-length
  readonly webSitePattern: RegExp = new RegExp(
    /^(https?:\/\/)?(\w+\.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
  );

  // Date formats
  dateFormat = 'DD/MM/YYYY';
  dateTimeFormat = 'DD/MM/YYYY HH:mm:ss';

  // Pagination's settings
  pageSizeOptions = [5, 10, 25, 50, 100];
  defaultPageSize = 25;

  // Default language id. This is a MongoDB ID
  defaultLanguageId = '6058e8dec492b2398594b253';

  socketMessages$: Subject<SocketMessage> = new Subject();

  /**
   * Class constructor
   */
  constructor(
    private apiService: ApiService,
    @Inject(Injector) private injector: Injector,
  ) {}

  // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
  // @see https://github.com/scttcper/ngx-toastr/issues/179#issuecomment-325724269
  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
  // @see https://github.com/scttcper/ngx-toastr/issues/179#issuecomment-325724269
  private get cookieService(): CookieService {
    return this.injector.get(CookieService);
  }

  /**
   * Simply getter that returns config settings loaded from BE
   *
   * @since 1.0.0
   */
  get settings() {
    return this.configSettings;
  }

  /**
   * Load config settings and translations from back-end
   *
   * @since 1.0.0
   */
  public async load(): Promise<any> {
    const endpoint = `fe-config`;
    const reloadFrequency = 3000;

    return new Promise((resolve, reject) => {
      this.apiService.apiCall('GET', endpoint).subscribe(
        async (response: any) => {
          if (!response.data) {
            setTimeout(() => {
              location.reload();
            }, reloadFrequency);
            reject(response);
            return;
          }
          this.configSettings = response.data;
          resolve(true);
        },
        (error: any) => {
          console.warn('error2: ', error);
          setTimeout(() => {
            location.reload();
          }, reloadFrequency);
          reject(error);
        },
      );
    });
  }

  /**
   * Init Socket.io-client and start listening for incoming data
   *
   * @since 1.0.0
   */
  public async initSocket(): Promise<void> {
    if (!this.configSettings?.socket?.domain) {
      return;
    }
    this.socket = io(this.configSettings.socket.domain, {
      query: {
        token: this.cookieService.get('s.id'),
      },
    });
    this.socket.emit('message', 'Ciao!');
    this.socket.on('message', (data: any) => {
      this.handleSocketMessage(data);
    });
  }

  /**
   * Handle incoming message data depending on it's type
   *
   * @since 1.0.0
   */
  private handleSocketMessage(rawData: any): void {
    try {
      if (!rawData.type) {
        return;
      }
      const { type, message, data } = rawData;
      switch (type) {
        case 'welcome':
          this.toastrService.info(message);
          break;
        default:
          this.socketMessages$.next(rawData);
          break;
      }
    } catch (error: any) {
      console.warn('error: ', error);
    }
  }
}
