<mat-sidenav-container class="app-container">
  <mat-sidenav #sidenav mode="side" opened class="app-sidenav app-sidenav-light" [fixedInViewport]="options.fixed"
    [fixedTopGap]="options.topGap" [fixedBottomGap]="options.bottomGap" [(opened)]="options.defaultOpen">
    <mat-nav-list>
      <a routerLink="/" class="logoLink">
        <img src="../../../../assets/img/logo/logo-100.png" alt="{{ configService?.settings?.owner?.nicename }}"
          width="150" />
      </a>

      <ng-container *ngFor="let item of navItems">
        <a mat-list-item *ngIf="!item.items && item.route === '' && item.permittedRoles.includes(currentRole)"
          routerLink="/{{ item.route }}" [ngClass]="{ active: activeRoute === '/' }">
          <div class="item-content">
            <mat-icon aria-hidden="false" *ngIf="item.icon" fontSet="fal" fontIcon="{{ item.icon }}"></mat-icon>
            {{ item.name }}
          </div>
        </a>

        <a mat-list-item
          *ngIf="!item.items && item.route && item.route !== '' && item.permittedRoles.includes(currentRole)"
          routerLink="/{{ item.route }}"
          [ngClass]="{ active: ((activeRoute.includes(item.route) && item.route !== '/') || (activeRoute === item.route && item.route === '/') ) }">
          <div class="item-content">
            <mat-icon aria-hidden="false" *ngIf="item.icon" fontSet="fal" fontIcon="{{ item.icon }}"></mat-icon>
            <ng-container>
              {{ item.name }}
            </ng-container>
          </div>
        </a>

        <div *ngIf="!item.items && !item.route && item.permittedRoles.includes(currentRole)" class="divider">
          <div class="item-content">
            <mat-icon aria-hidden="false" *ngIf="item.icon" fontSet="fal" fontIcon="{{ item.icon }}"></mat-icon>
            {{ item.name }}
          </div>
        </div>

        <mat-accordion *ngIf="item.items && item.permittedRoles.includes(currentRole)">
          <mat-expansion-panel class="mat-elevation-z shadow" [ngClass]="{'active': activeRoute.includes(item.route)}"
            [expanded]="activeRoute.includes(item.route)" (closed)="(false)">
            <mat-expansion-panel-header [collapsedHeight]="'45px'" [expandedHeight]="'45px'">
              <mat-panel-title>
                <div class="item-content">
                  <mat-icon aria-hidden="false" *ngIf="item.icon" fontSet="fal" fontIcon="{{ item.icon }}"></mat-icon>
                  {{ item.name }}
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
              <ng-container *ngFor="let subItem of item.items">
                <a mat-list-item routerLink="/{{ subItem.route }}" [routerLinkActive]="'active'">
                  <div class="item-content">
                    <mat-icon aria-hidden="false" *ngIf="subItem.icon" fontSet="fal" fontIcon="{{ subItem.icon }}">
                    </mat-icon>
                    {{ subItem.name }}
                  </div>
                </a>
              </ng-container>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="content-wrapper">
      <app-header></app-header>
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>

<div class="loader-wrapper" *ngIf="appLoading">
  <div class="loader">
    <mat-spinner></mat-spinner>
    <p class="mt-4 text-center">Caricamento...</p>
  </div> <!-- /.loader -->
</div> <!-- /.loader-wrapper -->
