<mat-toolbar class="app-footer">
  <div class="row w-100" style="width: 100%;">
    <div class="col-12 col-sm-6">
      <span>COPYRIGHT &copy; {{ year }} {{ configService?.settings?.owner?.nicename }}</span>
    </div>
    <!-- /.col-sm-6 -->
    <div class="col-12 col-sm-6 text-right">
      <a href="{{ configService?.settings?.owner?.url }}" target="_blank">{{ configService?.settings?.owner?.domain
        }}</a> | P.IVA: {{ configService?.settings?.owner?.vatNumber }}
    </div>
    <!-- /.col-sm-6 -->
  </div>
  <!-- /.row -->
</mat-toolbar>
