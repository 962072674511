// Core packages
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router,
} from '@angular/router';

// Third party packages
import { Observable } from 'rxjs';

// Custom packages
import { AuthService } from 'src/app/modules/auth/auth.service';
import IUser from '../models/user/user.interface';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  private loggedUser: IUser | undefined;

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    this.loggedUser = this.authService.loggedUser$.value;
    const { role } = this.loggedUser as IUser;
    const { permittedRoles } = route.data;

    const routeIsPermitted = permittedRoles.includes(role);
    if (!routeIsPermitted && !route.url.length) {
      switch (role) {
        case 'owner':
          this.router.navigate(['users']);
          break;
        case 'backOffice':
          this.router.navigate(['customers']);
          break;
        case 'sales':
          this.router.navigate(['customers']);
          break;
        case 'installer':
          this.router.navigate(['activities']);
          break;
        case 'account':
          this.router.navigate(['customers']);
          break;
        case 'operationsCenter':
          this.router.navigate(['']);
          break;
        default:
          this.router.navigate(['errors', '404']);
          break;
      }
    } else if (!routeIsPermitted) {
      return false;
    }

    return true;
  }
}
