// Core packages
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Third party packages

// Custom packages
import { AppWrapperComponent } from './components/app-wrapper/app-wrapper.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { RoleGuard } from './shared/guards/role.guard';

/**
 * Script start
 */
const routes: Routes = [
  {
    path: 'auth',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    pathMatch: 'prefix',
    component: AppWrapperComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: DashboardComponent,
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin', 'operationsCenter'],
        },
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./modules/users/users.module').then((m) => m.UsersModule),
      },

      {
        path: 'securities',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: [
            'admin',
            'owner',
            'backOffice',
            'account',
            'operationsCenter',
          ],
        },
        loadChildren: () =>
          import('./modules/suppliers/suppliers.module').then(
            (m) => m.SuppliersModule,
          ),
      },
      {
        path: 'installers',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin', 'owner', 'account', 'backOffice'],
        },
        loadChildren: () =>
          import('./modules/suppliers/suppliers.module').then(
            (m) => m.SuppliersModule,
          ),
      },
      {
        path: 'products',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin', 'owner', 'backOffice'],
        },
        loadChildren: () =>
          import('./modules/products/products.module').then(
            (m) => m.ProductsModule,
          ),
      },
      {
        path: 'contracts',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin', 'owner', 'backOffice', 'sales'],
        },
        loadChildren: () =>
          import('./modules/contracts/contracts.module').then(
            (m) => m.ContractsModule,
          ),
      },
      {
        path: 'customers',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin', 'owner', 'backOffice', 'sales', 'account'],
        },
        loadChildren: () =>
          import('./modules/customers/customers.module').then(
            (m) => m.CustomersModule,
          ),
      },
      {
        path: 'utilizers',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin', 'owner', 'backOffice', 'sales', 'account'],
        },
        loadChildren: () =>
          import('./modules/utilizers/utilizers.module').then(
            (m) => m.UtilizersModule,
          ),
      },
      {
        path: 'activities',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: [
            'admin',
            'owner',
            'backOffice',
            'account',
            'installer',
          ],
        },
        loadChildren: () =>
          import('./modules/activities/activities.module').then(
            (m) => m.ActivitiesModule,
          ),
      },
      {
        path: 'installations',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin'],
        },
        loadChildren: () =>
          import('./modules/installations/installations.module').then(
            (m) => m.InstallationsModule,
          ),
      },
      {
        path: 'tickets',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin', 'owner', 'backOffice'],
        },
        loadChildren: () =>
          import('./modules/tickets/tickets.module').then(
            (m) => m.TicketsModule,
          ),
      },
      {
        path: 'translations',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin'],
        },
        loadChildren: () =>
          import('./modules/translations/translations.module').then(
            (m) => m.TranslationsModule,
          ),
      },
      {
        path: 'languages',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin'],
        },
        loadChildren: () =>
          import('./modules/languages/languages.module').then(
            (m) => m.LanguagesModule,
          ),
      },
      {
        path: 'logs',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin'],
        },
        loadChildren: () =>
          import('./modules/logs/logs.module').then((m) => m.LogsModule),
      },
      {
        path: 'settings',
        canActivate: [RoleGuard],
        data: {
          permittedRoles: ['admin', 'owner'],
        },
        component: SettingsComponent,
      },
      {
        path: 'errors',
        loadChildren: () =>
          import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
      },
    ],
  },
  { path: '**', redirectTo: 'auth/login' },
  // { path: '**', redirectTo: 'errors/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
