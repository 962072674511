// Core packages
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

// Third party packages
import { fromEvent, merge, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import moment from 'moment';

// Custom packages
import TableConfig from '../../interfaces/tableConfig.interface';
import { ConfigService } from '../../services/config.service';

/**
 * Script start
 */
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  @Input('config') config!: TableConfig<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('input', { static: false }) input!: ElementRef;
  public displayedColumns: string[] = [];
  public selection = new SelectionModel<any>(true, []);
  public showFilters: boolean = false;
  private rows: any[] = [];

  constructor(public configService: ConfigService) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    // By default show general search
    if (this.config && typeof this.config?.generalSearch === 'undefined') {
      this.config.generalSearch = true;
    }

    // Calculate displayed cols
    let hasActions = true;
    if (this.config.hideActionsColumn) {
      hasActions = false;
    }
    if (this.config.selectable) {
      this.displayedColumns = [
        'select',
        ...this.config.columns
          .filter((col) => col.visible)
          .map((col) => col.key),
      ];
    } else {
      this.displayedColumns = [
        ...this.config.columns
          .filter((col) => col.visible)
          .map((col) => col.key),
      ];
    }
    if (hasActions) {
      this.displayedColumns.push('actions');
    }

    // If at least 1 col has a filter, than enable filters
    this.showFilters = this.config.columns.some((col) => col.filter);
  }

  /**
   * Invoked immediately after Angular has completed
   * initialization of a component's view.
   */
  ngAfterViewInit(): void {
    // Load initial data
    this.loadPage();

    // Reset pagination after sorting
    this.subscriptions.push(
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0)),
    );

    // Once user change sorting OR change page
    // let's reload the page data
    this.subscriptions.push(
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(tap(() => this.loadPage()))
        .subscribe(),
    );

    // Subscribe to rows
    this.subscriptions.push(
      this.config.dataSource.connect().subscribe((res) => (this.rows = res)),
    );

    // Handle server-side filtering through general search
    if (this.input?.nativeElement) {
      this.subscriptions.push(
        fromEvent(this.input.nativeElement, 'keyup')
          .pipe(
            debounceTime(350), // max 1 query every 350 milliseconts
            distinctUntilChanged(), // Eliminate duplicate values
            tap(() => {
              this.paginator.pageIndex = 0; // Reset page
              this.loadPage();
            }),
          )
          .subscribe(),
      );
    }

    // Handle server-side filtering through column-specific search
    // Why do we use "null as string"? --> @see https://github.com/ReactiveX/rxjs/issues/4772
    this.config.columns.forEach((col) => {
      if (col.filter) {
        const subscription = col.filter.control.valueChanges
          .pipe(
            debounceTime(350), // max 1 query every 350 milliseconts
            distinctUntilChanged(), // Eliminate duplicate values
            tap(() => {
              this.paginator.pageIndex = 0; // Reset page
              this.loadPage();
            }),
          )
          .subscribe();
        this.subscriptions.push(subscription);
      }
    });

    // Reset page once "refresh$" receive a new value
    this.subscriptions.push(
      this.config.refresh$.pipe(skip(1)).subscribe((val: boolean) => {
        this.loadPage();
      }),
    );

    // Reset filters once "reset$" receive a new value
    this.subscriptions.push(
      this.config.reset$.pipe(skip(1)).subscribe((val: boolean) => {
        this.onResetFilters();
      }),
    );
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Load requested data page
   *
   * @since 1.0.0
   */
  loadPage(): void {
    const start = this.paginator.pageIndex * this.paginator.pageSize;
    const length = this.paginator.pageSize;
    let search: any = {};
    if (this.config.defaultSearch) {
      search = { ...this.config.defaultSearch };
    }
    this.config.columns.forEach((col) => {
      const filterKey = col?.filter?.filterKey || col?.key;

      if (col.filter) {
        let val = col.filter.control.value;
        if (col.filter.dataType === 'date' && val && moment(val).isValid()) {
          search[filterKey] = moment(val).format('YYYY-MM-DD');
        } else if (val) {
          search[filterKey] = val;
        } else if (typeof col.filter.defaultValue !== 'undefined') {
          search[filterKey] = col.filter.defaultValue;
        }
      }
    });
    if (this.input?.nativeElement?.value) {
      search.general = this.input.nativeElement.value;
    }
    this.config.dataSource.loadItems(
      start,
      length,
      this.sort.active,
      this.sort.direction,
      search,
    );
  }

  /**
   * Check whether the number of selected elements
   * matches the total number of rows.
   *
   * @since 1.0.0
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.rows?.length;
    return numSelected === numRows;
  }

  /**
   * Selects all rows if they are not all selected;
   * otherwise clear selection.
   *
   * @since 1.0.0
   */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.rows.forEach((row) => this.selection.select(row));
  }

  /**
   * The label for the checkbox on the passed row
   *
   * @since 1.0.0
   */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  /**
   * Reset given filter value and reload the page
   *
   * @since 1.0.0
   */
  onResetFilter(filter: UntypedFormControl, emitChanges: boolean = true): void {
    filter.reset(null, {
      emitEvent: emitChanges,
    });
    if (emitChanges) {
      this.loadPage();
    }
  }

  /**
   * Reset table's filters
   *
   * @since 1.0.0
   */
  onResetFilters(): void {
    // Reset general filters
    if (this.input?.nativeElement) {
      this.input.nativeElement.value = '';
    }

    // Reset column filters
    this.config.columns.forEach((col) => {
      if (col.filter) {
        this.onResetFilter(col.filter.control, false);
      }
    });

    // Reset tables' sorting versus and column
    this.sort.direction = 'desc';
    this.sort.active = 'createdAt';
    this.sort._stateChanges.next();

    // Reset paginator
    this.paginator.pageIndex = 0;
    this.paginator.pageSize =
      this.config.pageSize || this.configService.defaultPageSize;

    // Reload table's data
    this.loadPage();
  }

  /**
   * Reset general search input and dispath a 'keyup' event
   * so that the table re-render his rows
   *
   * @since 1.0.0
   */
  resetInput(): void {
    this.input.nativeElement.value = '';
    const event = new KeyboardEvent('keyup');
    this.input.nativeElement.dispatchEvent(event);
  }
}
