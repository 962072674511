// Core packages
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

// Third party packages
import {
  NgxMatDateFormats,
  NGX_MAT_DATE_FORMATS,
} from '@angular-material-components/datetime-picker';
import { FieldType } from '@ngx-formly/material';

// Custom packages

/**
 * Script start
 */
const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    // dateInput: 'l, LTS',
    dateInput: 'DD/MM/YYYY HH:mm',
  },
  display: {
    // dateInput: 'l, LTS',
    dateInput: 'DD/MM/YYYY HH:mm',
    // monthYearLabel: 'MMM YYYY',
    monthYearLabel: 'DD/MM/YYYY',
    // dateA11yLabel: 'LL',
    dateA11yLabel: 'DD/MM/YYYY',
    // monthYearA11yLabel: 'MMMM YYYY',
    monthYearA11yLabel: 'DD/MM/YYYY',
  },
};
@Component({
  selector: 'app-formly-datetimepicker-type',
  template: `
    <div class="d-flex">
      <input
        matInput
        [ngxMatDatetimePicker]="picker"
        [placeholder]="to.placeholder || 'Seleziona'"
        [(ngModel)]="this.value"
        [min]="to.minDate"
        [max]="to.maxDate"
        [disabled]="to.disabled || false"
        (focus)="picker.open()"
      />

      <ng-template #matSuffix>
        <mat-datepicker-toggle [for]="$any(picker)"></mat-datepicker-toggle>
      </ng-template>
      <ngx-mat-datetime-picker
        #picker
        [showSpinners]="to.showSpinners"
        [showSeconds]="to.showSeconds"
        [stepHour]="to.stepHour"
        [stepMinute]="to.stepMinute"
        [stepSecond]="to.stepSecond"
        [touchUi]="to.touchUi"
        [color]="to.color"
        [enableMeridian]="to.enableMeridian"
        [disableMinute]="to.disableMinute"
        [hideTime]="to.hideTime"
        dateFormat="DD/MM/YYYY"
      >
        <ng-template>
          <span>Ok</span>
        </ng-template>
      </ngx-mat-datetime-picker>

      <mat-icon
        matSuffix
        fontSet="far"
        fontIcon="fa-calendar-day"
        class="toggler"
        (click)="picker.open()"
      ></mat-icon>
    </div>
  `,
  styles: [
    `
      .toggler {
        font-size: 18px;
        position: absolute;
        right: 7px;
        bottom: 10px;
        cursor: pointer;
        opacity: 0.6;
      }
    `,
  ],
  providers: [
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: CUSTOM_DATE_FORMATS,
    },
  ],
})
export class MaterialDatetimepickerTypeComponent
  extends FieldType
  implements OnInit, AfterViewInit, OnDestroy
{
  constructor() {
    super();
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    super.ngOnInit();
  }

  /**
   * Handle afterViewInit of this component
   *
   * @since 1.0.0
   */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  /**
   * Handle ngOnDestroy of this component
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
