// Core packages
import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

// Third party packages
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

// Custom packages
import { SidenavService } from '../../services/sidenav.service';
import { PageService } from '../../services/loading.service';
import { ConfigService } from '../../services/config.service';
import { AuthService } from 'src/app/modules/auth/auth.service';

/**
 * Script start
 */
export interface SidenavItem {
  name: string;
  icon?: string;
  route?: string;
  items?: SidenavItem[];
  permittedRoles: string[];
}

@HostListener('window:resize', ['$event'])
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  @ViewChild('sidenav', { static: false }) public sidenav:
    | MatSidenav
    | undefined;
  sidenavInitialized = false;
  options: any;
  navItems: any[] = [];
  activeRoute: string = '';
  screenHeight: any;
  screenWidth: any;
  mobileScreenWidth = 768;
  hdScreenWidth = 991; // 1369;
  public appLoading: boolean = false;
  public currentRole!: string;

  /**
   * Class constructor
   */
  constructor(
    private router: Router,
    private sidenavService: SidenavService,
    private pageService: PageService,
    public configService: ConfigService,
    public authService: AuthService,
  ) {
    this.getScreenSize();
    this.currentRole = this.authService.loggedUser$.value?.role as string;
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    // Set sidenav options
    const defaultOpen =
      this.screenWidth <= this.mobileScreenWidth ? false : true;
    this.options = {
      topGap: 0,
      bottomGap: 0,
      fixed: true,
      defaultOpen,
    };

    this.activeRoute = this.router.routerState.snapshot.url;

    // Get nav items for sidenav menu
    this.navItems = this.getNavItems();

    // Close sidenav on route select
    this.router.events.subscribe(() => {
      if (window.innerWidth < this.hdScreenWidth) {
        this.close();
      }
    });

    // Subscribe to "app loading" event
    this.subscriptions.push(
      this.pageService.loading$
        .pipe(debounceTime(200), distinctUntilChanged())
        .subscribe((val: boolean) => (this.appLoading = val)),
    );
  }

  /**
   * After view has been initialized
   *
   * @since 1.0.0
   */
  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);

    // Get current route for active status
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.activeRoute = event.url;
        // Close sidenav on mobile after user
        // clicked on some item
        if (this.screenWidth <= this.mobileScreenWidth) {
          this.close();
        }
      }
    });
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Handle sidenav close event closing sidenav
   *
   * @since 1.0.0
   */
  close(): void {
    this.sidenav?.close();
  }

  /**
   * Get nav items
   *
   * @since 1.0.0
   *
   * @returns array of sidenavItems objects
   */
  getNavItems(): SidenavItem[] {
    const items: SidenavItem[] = [
      {
        name: 'Dashboard',
        icon: 'fa-home',
        route: '/',
        permittedRoles: [
          'admin',
          // 'owner',
          // 'backOffice',
          // 'sales',
          'operationsCenter',
        ],
      },
      {
        name: 'Anagrafiche',
        permittedRoles: ['admin', 'owner', 'backOffice', 'sales', 'account'],
      },
      {
        name: 'Utenti',
        icon: 'fa-users-crown',
        route: '/users',
        permittedRoles: ['admin', 'owner', 'backOffice'],
        // items: [
        //   {
        //     name: 'Elenco',
        //     icon: 'fa-list-ul',
        //     route: '/users',
        //   },
        //   {
        //     name: 'Aggiungi',
        //     route: '/users/add',
        //     icon: 'fa-plus-circle',
        //   },
        // ],
      },
      {
        name: 'Clienti',
        icon: 'fa-university',
        route: '/customers',
        permittedRoles: ['admin', 'owner', 'backOffice', 'sales', 'account'],
      },
      {
        name: 'Utilizzatori',
        icon: 'fa-users',
        route: '/utilizers',
        permittedRoles: ['admin', 'owner', 'backOffice', 'sales', 'account'],
      },
      {
        name: 'Prodotti',
        icon: 'fa-boxes',
        route: '/products',
        permittedRoles: ['admin', 'owner', 'backOffice'],
      },
      {
        name: 'Contratti',
        icon: 'fa-file',
        route: '/contracts',
        permittedRoles: ['admin', 'owner', 'backOffice', 'sales'],
      },
      {
        name: 'Fornitori',
        permittedRoles: [
          'admin',
          'owner',
          'backOffice',
          'account',
          'operationsCenter',
        ],
      },
      {
        name: 'Vigilanze',
        icon: 'fa-shield-alt',
        route: '/securities',
        permittedRoles: [
          'admin',
          'owner',
          'backOffice',
          'account',
          'operationsCenter',
        ],
      },
      {
        name: 'Installatori',
        icon: 'fa-user-cog',
        route: '/installers',
        permittedRoles: ['admin', 'owner', 'account', 'backOffice'],
      },
      // Installers (only) must see a calendar in the sidenav
      {
        name: 'Calendario',
        icon: 'fa-calendar-alt',
        route: '/activities/calendar',
        permittedRoles: ['installer'],
      },
      {
        name: 'Organizzazione',
        permittedRoles: ['admin', 'owner', 'backOffice', 'account'],
      },
      // Label only for installer
      {
        name: 'Interventi',
        permittedRoles: ['installer'],
      },
      {
        name: 'Attività',
        icon: 'fa-calendar-alt',
        route: '/activities',
        permittedRoles: ['admin', 'owner', 'backOffice', 'account'],
      },
      {
        name: 'Nuove installazioni',
        icon: 'fa-plus-hexagon',
        route: '/activities/type/installation',
        permittedRoles: ['installer'],
      },
      {
        name: 'Manutenzioni',
        icon: 'fa-wrench',
        route: '/activities/type/maintenance',
        permittedRoles: ['installer'],
      },
      {
        name: 'Rimozioni',
        icon: 'fa-minus-hexagon',
        route: '/activities/type/uninstall',
        permittedRoles: ['installer'],
      },
      {
        name: 'Installazioni',
        icon: 'fa-wrench',
        route: '/installations',
        permittedRoles: ['admin'],
      },
      {
        name: 'Supporto',
        permittedRoles: ['admin', 'owner', 'backOffice'],
      },
      {
        name: 'Ticket',
        icon: 'fa-calendar-alt',
        route: '/tickets',
        permittedRoles: ['admin', 'owner', 'backOffice'],
      },
      {
        name: 'Log',
        icon: 'fa-list-alt',
        route: '/logs',
        permittedRoles: ['admin'],
      },
      {
        name: 'Impostazioni',
        permittedRoles: ['admin', 'owner'],
      },
      {
        name: 'Traduzioni',
        icon: 'fa-pen-alt',
        route: '/translations',
        permittedRoles: ['admin'],
      },
      {
        name: 'Lingue',
        icon: 'fa-language',
        route: '/languages',
        permittedRoles: ['admin'],
      },
      {
        name: 'Impostazioni',
        icon: 'fa-cogs',
        route: '/settings',
        permittedRoles: ['admin', 'owner'],
      },
    ];
    return items;
  }

  /**
   * Get current viewport sizes
   *
   * @since 1.0.0
   */
  getScreenSize(): void {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
}
