// Core packages
import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

// Third party packages
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import Address from '../interfaces/address.interface';
import { AuthService } from 'src/app/modules/auth/auth.service';

// Custom packages

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class HelperService {
  /**
   * Class constructor
   */
  constructor(
    private toastrService: ToastrService,
    private authService: AuthService,
  ) {}

  /**
   * Emit a toastr notification with given data
   *
   * @since 1.0.0
   *
   * @param error Emitted error
   * @param ?type If we want to override the error data, here
   * we must provide a toastr notification type 'error' or 'success'
   * @param ?message Override error message
   * @param ?title  Override error title
   * @returns Observable<boolean>
   */
  handleError(
    error: any,
    type?: string,
    message?: string,
    title?: string,
  ): Observable<boolean> {
    console.warn('handleError()', error);
    if (error?.error?.data?.logout) {
      console.warn('DEVO LOGGARE FUORI');
      this.authService.logout();
      location.reload();
      return of(false);
    }
    if (typeof type !== 'undefined' && typeof message !== 'undefined') {
      if (type === 'error') {
        if (typeof title !== 'undefined') {
          this.toastrService.error(message, title);
          return of(false);
        }
        this.toastrService.error(message);
        return of(false);
      }
      if (type === 'success') {
        if (typeof title !== 'undefined') {
          this.toastrService.success(message, title);
          return of(false);
        }
        this.toastrService.success(message);
        return of(false);
      }
    }

    if (error.data) {
      console.warn('1');
      Object.keys(error.data).forEach((key) => {
        this.toastrService.error(error.data[key].msg);
      });
      return of(false);
    }

    if (
      typeof error.error !== 'undefined' &&
      error?.error?.data &&
      Object.keys(error?.error?.data).length > 0
    ) {
      console.warn('2');
      Object.keys(error.error.data).forEach((key) => {
        // this.toastrService.error(error.error.data[key].msg, `Error in ${error.error.data[key].param}`);
        this.toastrService.error(error.error.data[key].msg);
      });
      return of(false);
    }

    if (typeof error.error !== 'undefined' && error.error.message) {
      console.warn('3');
      this.toastrService.error(error.error.message, 'Attenzione');
      return of(false);
    }

    if (error.message) {
      console.warn('4');
      this.toastrService.error(error.message);
      return of(false);
    }

    // Generic error message
    console.warn('5');
    this.toastrService.error(
      "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico",
      'Errore',
    );

    return of(false);
  }

  /**
   * Parse error coming from an httpRequest made
   * with { responseType: blob }
   *
   * @see https://stackoverflow.com/questions/48500822/how-to-handle-error-for-response-type-blob-in-httprequest
   *
   * @since 1.0.0
   */
  parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();
    const obs = new Observable((observer: any) => {
      reader.onloadend = (e) => {
        if (reader.result) {
          observer.error(JSON.parse(reader.result.toString()));
        }
        observer.complete();
      };
    });
    reader.readAsText(err.error);
    return obs;
  }

  /**
   * Handle back-end errors showing them under the
   * appropriate form-control in given form instance
   *
   * @since 1.0.0
   */
  handleFormError(form: UntypedFormGroup, err: any): void {
    console.warn('handleFormError()', form, err);
    const errors: any[] = [];

    // Check data is defined
    if (
      typeof err.error === 'undefined' ||
      typeof err.error.data === 'undefined'
    ) {
      console.warn('err.error.data is undefined');
      this.handleError(err); // Fall back to toastr
      return;
    }

    // Convert data object to array
    Object.keys(err.error.data).forEach((key) => {
      errors.push(err.error.data[key]);
    });

    // Set form invalid
    errors.forEach((error) => {
      if (typeof form.controls[error.param] !== 'undefined') {
        const field = form.get(error.param);
        if (field) {
          field.markAsTouched();
          field.markAsDirty();
          field.setErrors(error.msg);
        }
      } else {
        // Ok, so form field is undefined, looks like back-end
        // found an error on a field that is missing in the front-end
        // This generally happends when we forgot a field on the
        // front-end. Let's show a nice toastr notification
        this.toastrService.error(`${error.msg}: ${error.param}`);
      }
    });
  }

  /**
   * Handle invalid status of form controls setting
   * dirty and touched class to those form controls
   *
   * @since 1.0.0
   */
  handleFormInvalid(form: UntypedFormGroup): void {
    console.warn('handleFormInvalid()', form);
    Object.keys(form.controls).forEach((controlKey) => {
      const control = form.get(controlKey);
      if (control?.invalid) {
        console.warn(`Make control ${controlKey} dirty()`);
        control.markAsDirty();
        control.markAsTouched();
        control.setErrors({
          'back-end': 'booh', // @todo: translate
        });
      }
    });
  }

  /**
   * Check if given value is in string type or not
   *
   * @since 1.0.0
   */
  isString(x: any): boolean {
    return Object.prototype.toString.call(x) === '[object String]';
  }

  /**
   * Check if given form control is required or not
   *
   * @since 1.0.0
   *
   * @param control form control to check
   * @returns boolean True if it's required, false otherwhise.
   */
  controlIsRequired(control: UntypedFormControl | AbstractControl): boolean {
    if (
      typeof control === 'undefined' ||
      typeof control.validator !== 'function'
    ) {
      return false;
    }
    const validator = control.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
    return false;
  }

  /**
   * Generate an appropriate error message starting from given data
   *
   * @since 1.0.0
   */
  getErrorMessage(key: string, value: any): string {
    console.warn(`key: ${key} has error with value`, value);
    let message = '';
    switch (key) {
      case 'required':
        message = 'Campo obbligatorio';
        break;
      case 'email':
        message = 'Email invalida';
        break;
      case 'minlength':
        message = `Minimo ${value.requiredLength} caratteri`;
        break;
      case 'maxlength':
        message = `Massimo ${value.requiredLength} caratteri`;
        break;
      case 'back-end':
        message = value.msg;
        break;
      default:
        message = 'Campo invalido';
        break;
    }

    return message;
  }

  /**
   * Format given address object and return it
   * as a string
   *
   * @since 1.0.0
   */
  getFormattedAddress(address: Address): string {
    if (
      !address ||
      !address.route ||
      !address.streetNumber ||
      !address.postalCode ||
      !address.city ||
      !address.countryCodeTwo
    ) {
      return '';
    }
    const addressString = `${address.route} ${address.streetNumber}, ${address.postalCode} ${address.city}, ${address.countryCodeTwo}`;
    return addressString;
  }

  /**
   * Handle back-end errors coming from a ngx-formly form
   * and set the errors to the form (they will than be showed
   * as mat-error)
   *
   * @since 1.0.0
   */
  handleFormlyError(form: UntypedFormGroup, err: any): void {
    console.log('handleFormlyError(form, err)', form, err);

    const errors = [];

    // Check data is defined
    if (
      typeof err.error === 'undefined' ||
      typeof err.error.data === 'undefined'
    ) {
      console.warn('err.error.data is undefined');
      this.handleError(err); // Fall back to toastr
      return;
    }

    // Set custom back-end errors to the form
    Object.keys(err.error.data).forEach((key: string) => {
      let fieldKey = key;
      const phoneKeys = [
        'phone.prefix',
        'phone.number',
        'mobile.prefix',
        'mobile.number',
        'cell.prefix',
        'cell.number',
        'simPhone.prefix',
        'simPhone.number',
      ];
      if (phoneKeys.includes(key)) {
        const foundPhoneKey = phoneKeys.find((el: string) => el === key);
        fieldKey = foundPhoneKey?.split('.')[0] as string;
      }
      const field = form.get(fieldKey);
      console.log('key', key);
      console.log('fieldKey', fieldKey);
      console.log('field', field);
      if (form && field) {
        // Standard controls
        field.markAsTouched();
        field.markAsDirty();
        console.warn('ERR_TEXT: ', err.error.data[key]);
        const errMsg = err.error.data[key].msg;
        field.setErrors({
          'server-error': errMsg,
        });
      } else {
        // Fall back
        console.warn(
          'fallback',
          `${err.error.data[key].msg}: ${err.error.data[key].param}`,
        );
        // Ok, so form field is undefined, looks like back-end
        // found an error on a field that is missing in the front-end
        // This generally happends when we forgot a field on the
        // front-end. Let's show a nice toastr notification
        this.toastrService.error(
          `${err.error.data[key].msg}: ${err.error.data[key].param}`,
        );
      }
    });
  }
}
