<app-page>
  <div class="container-fluid">

    <div class="row">

      <div class="col-12">
        <div class="breadcrumb-wrapper">
          <div class="title">
            Impostazioni
          </div>
          <ul class="breadcrumb">
            <li>
              <mat-icon fontSet="fal" fontIcon="fa-home"></mat-icon>
            </li>
            <li>
              Impostazioni
            </li>
          </ul> <!-- /.breadcrumb -->
        </div> <!-- /.breadcrumb-wrapper -->
      </div> <!-- /.col -->

      <div class="col-12">
        <mat-card class="mat-elevation-z shadow">
          <mat-card-content class="p-0">

            <div class="table-wrapper">
              <div class="table-responsive">
                <table class="mat-table">
                  <thead>
                    <tr class="mat-header-row">
                      <th class="mat-header-cell">
                        <div class="mat-sort-header-content">#</div>
                      </th>
                      <th class="mat-header-cell">
                        <div class="mat-sort-header-content">Nome</div>
                      </th>
                      <th class="mat-header-cell">
                        <div class="mat-sort-header-content">Valore</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="mat-row">
                      <td class="mat-cell">1</td>
                      <td class="mat-cell">Ambiente</td>
                      <td class="mat-cell">{{ configService?.settings?.env === 'prod' ? 'Produzione' : 'Sviluppo' }}
                      </td>
                    </tr>
                    <tr class="mat-row">
                      <td class="mat-cell">2</td>
                      <td class="mat-cell">Valuta</td>
                      <td class="mat-cell">{{ configService?.settings?.i18n.currency }}</td>
                    </tr>
                    <tr class="mat-row">
                      <td class="mat-cell">3</td>
                      <td class="mat-cell">Lingue</td>
                      <td class="mat-cell">{{ configService?.settings?.i18n.availableLanguages.join(', ') }}</td>
                    </tr>
                    <tr class="mat-row">
                      <td class="mat-cell">4</td>
                      <td class="mat-cell">Data primo sviluppo</td>
                      <td class="mat-cell">Settembre 2021</td>
                    </tr>
                    <!-- <tr class="mat-row">
                      <td class="mat-cell">4</td>
                      <td class="mat-cell">Moduli</td>
                      <td class="mat-cell">{{ getModules() }}</td>
                    </tr> -->
                    <tr class="mat-row">
                      <td class="mat-cell">5</td>
                      <td class="mat-cell">Proprietario</td>
                      <td class="mat-cell">{{ configService?.settings?.owner?.name }}<br>PIVA: {{
                        configService?.settings?.owner?.vatNumber }}<br> Indirizzo: {{
                        configService?.settings?.owner?.address?.route }}, {{
                        configService?.settings?.owner?.address?.streetNumber }} - {{
                        configService?.settings?.owner?.address?.postalCode }} {{
                        configService?.settings?.owner?.address?.city }} ({{
                        configService?.settings?.owner?.address?.administrativeArea2 }})</td>
                    </tr>
                    <tr class="mat-row">
                      <td class="mat-cell">6</td>
                      <td class="mat-cell">Recapiti Proprietario</td>
                      <td class="mat-cell">Telefono: {{ configService?.settings?.owner?.phone }} | Email: {{
                        configService?.settings?.owner?.email }}</td>
                    </tr>
                    <tr class="mat-row">
                      <td class="mat-cell">7</td>
                      <td class="mat-cell">Sviluppatore</td>
                      <td class="mat-cell">Società Italiana Tecnologia e Informatica, S.I.T.I. srl <br>PIVA: 08224730963
                        <br>Indirizzo: Via Italia, 46 - 20900 Monza (MB)
                      </td>
                    </tr>
                    <tr class="mat-row">
                      <td class="mat-cell">8</td>
                      <td class="mat-cell">Recapiti sviluppatore</td>
                      <td class="mat-cell">Telefono: +390392271101 | Email: info@sitisrl.it</td>
                    </tr>
                  </tbody>
                </table>
              </div> <!-- /.table-responsive -->
            </div> <!-- /.table-wrapper -->

          </mat-card-content>
        </mat-card>
      </div> <!-- /.col -->
    </div> <!-- /.row -->

  </div> <!-- /.container -->
</app-page>
