// Core packages
import { NgModule } from '@angular/core';

// Third party packages

// Custom packages
import { ErrorsRoutingModule } from 'src/app/modules/errors/errors-routing.module';
import { Error404Component } from 'src/app/modules/errors/pages/error-404/error-404.component';
import { SharedModule } from 'src/app/modules/shared.module';

/**
 * Script start
 */
@NgModule({
  imports: [
    // Shared modules
    SharedModule,

    // Third party

    // Custom
    ErrorsRoutingModule,
  ],
  declarations: [Error404Component],
})
export class ErrorsModule {}
