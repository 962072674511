// Core packages
import { Injectable } from '@angular/core';

// Third party packages
import { Observable } from 'rxjs';

// Custom packages
import { ApiService } from './api.service';

/**
 * Script start
 */
@Injectable({
  providedIn: 'root',
})
export class FileService {
  /**
   * Class constructor
   */
  constructor(private apiService: ApiService) {}

  /**
   * Upload file
   *
   * @since 1.0.0
   *
   * @returns Observable<any>
   */
  uploadTempFile(file: FormData): Observable<any> {
    return this.apiService.apiCall('POST', 'temp-files', file, {
      contentType: 'auto',
    });
  }
}
