// Core packages

// Third party packages

// Custom packages
import Address from '../../interfaces/address.interface';
import Phone from '../../interfaces/phone.interface';
import { BaseModel } from '../_base/model.class';
import UserTranslation from './userTranslation.interface';

/**
 * Script start
 */
export class User extends BaseModel {
  constructor(
    private _id?: string,
    private role?: string,
    private profileImage?: string,
    private email?: string,
    private firstName?: string,
    private lastName?: string,
    private fullName?: string,
    private phone?: Phone,
    private taxCode?: string,
    private preferredLanguage?: string,
    private description?: string,
    private address?: Address,
    private residentialAddress?: Address,
    private image?: string,
    private birthDate?: Date,
    private birthNationality?: string,
    private publicPage?: string,
    private loyalityLevel?: string,
    private receiveGsMessage?: string,
    private hostLevel?: string,
    private receiveSmsNotification?: boolean,
    private preferredPaymentType?: string,
    private businessType?: string,
    private merchantCategoryCode?: string,
    private url?: string,
    private termosOfService?: boolean,
    private externalAccount?: string,
    private company?: any,
    private individual?: any,
    private stripeId?: string,

    private registrationIp?: string,
    private active?: string,
    private confirmed?: string,
    private tosAcceptance?: string,
    private privateAddress?: string,

    private spokenLanguages?: string[],
    private gender?: string,
    private translations?: UserTranslation[],
    private phoneConfirmed?: boolean,
    private stripeVerification?: boolean,

    private createdAt?: Date,
    private createdBy?: string,
    private updatedAt?: Date,
    private updatedBy?: string,
  ) {
    super();

    // @todo complete
    this.setRequiredFields([
      '_id',
      'role',
      'email',
      'firstName',
      'lastName',
      'fullName',
      'registrationIp',
      'active',
      'confirmed',
      'tosAcceptance',
      'createdAt',
      'createdBy',
    ]);
  }
}
