// Core packages
import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// Third party packages

// Custom packages

/**
 * Script start
 */
@Pipe({
  name: 'safeHtml',
})
export default class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any): any {
    const context: SecurityContext = 1;
    const sanitized = this.sanitizer.sanitize(context, value);
    return sanitized;

    const safeHtml = this.sanitizer.bypassSecurityTrustHtml(value);
    return safeHtml;
  }
}
