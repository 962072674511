<mat-card class="stat-card mat-elevation-z shadow" (click)="onClick()" [ngClass]="{'has-link': url}">
  <div class="row first-row">
    <div class="col first-col">
      <div class="icon-wrapper" [ngStyle]="{'background-color': backgroundColor}">
        <mat-icon fontSet="fal" [fontIcon]="icon" [ngStyle]="{'color': color}">
        </mat-icon>
      </div>
      <span class="descriptor" [innerHTML]="descriptor" [ngStyle]="{'color': color}"></span>
    </div> <!-- /.col -->
    <div class="col second-col" *ngIf="value">
      <span class="value">{{ value }}</span>
    </div> <!-- /.col -->
  </div> <!-- /.row -->
</mat-card>
