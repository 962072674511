<div class="table-wrapper">
  <div class="spinner-container" *ngIf="config.dataSource.loading$ | async">
    <mat-spinner strokeWidth="3" [diameter]="50" mode="indeterminate"></mat-spinner>
  </div> <!-- /.spinner-container -->

  <div class="table-toolbar">
    <div class="row align-items-center">
      <div class="col-12 col-sm-6 col-md-6">
        <ng-container *ngIf="config?.generalSearch">
          <mat-form-field appear>
            <mat-label>Cerca...</mat-label>
            <input matInput placeholder="Scrivi qui..." #input>
            <mat-icon matSuffix fontSet="fal" fontIcon="fa-search" *ngIf="!input.value"></mat-icon>
            <mat-icon matSuffix fontSet="fal" fontIcon="fa-times" (click)="resetInput()" class="reset-input-icon"
              *ngIf="input.value"></mat-icon>
          </mat-form-field>
        </ng-container>
      </div> <!-- /.col-12.col-md-4 -->
      <div class="col-12 col-sm-6 col-md-6 d-flex justify-content-end">
        <ng-container *ngFor="let item of config?.options">
          <button mat-button (click)="item.callback()" class="d-none d-sm-inline-block" [title]="item?.title || ''">
            <ng-container *ngIf="item?.icon">
              <mat-icon fontSet="fal" [fontIcon]="item.icon || ''"></mat-icon>
            </ng-container>
            {{ item.name }}
          </button>
        </ng-container>
      </div> <!-- /.col-12.col-md-8 -->
    </div> <!-- /.row -->
  </div> <!-- /.table-toolbar -->

  <div class="table-responsive">
    <table mat-table [dataSource]="config.dataSource" matSort [matSortActive]="config.sort || 'createdAt'"
      [matSortDirection]="config.sortVersus || 'desc'" matSortDisableClear multiTemplateDataRows>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
        <ng-container *ngIf="showFilters">
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
      </ng-container>

      <ng-container *ngFor="let col of config.columns">
        <ng-container [matColumnDef]="col.key">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!col.sortable">{{ col.label }}</th>

          <ng-container *ngIf="col.render">
            <td mat-cell *matCellDef="let row">
              <div [innerHTML]="col.render(row, col.key) | safeHtml"></div>
            </td>
          </ng-container>

          <ng-container *ngIf="!col.render">
            <td mat-cell *matCellDef="let row" [innerHTML]="row[col.key]"></td>
          </ng-container>

          <ng-container *ngIf="showFilters">
            <td mat-footer-cell *matFooterCellDef>
              <mat-form-field class="filter" *ngIf="col.filter && col.filter.type !== 'autocomplete'">
                <mat-label>{{col.filter.label || "Cerca..."}}</mat-label>

                <!-- TYPE === 'input' -->
                <ng-container *ngIf="col.filter.type === 'input'">
                  <input matInput [type]="col.filter.dataType" [formControl]="col.filter.control">
                </ng-container>

                <!-- TYPE === 'select' -->
                <ng-container *ngIf="col.filter.type === 'select'">
                  <mat-select [formControl]="col.filter.control">
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let item of col.filter.items" [value]="item.value">
                      <small>{{item.label}}</small>
                    </mat-option>
                  </mat-select>
                </ng-container>

                <!-- TYPE === 'datepicker' -->
                <ng-container *ngIf="col.filter.type === 'datepicker'">
                  <input matInput [matDatepicker]="dp" (focus)="dp.open()" (click)="dp.open()" readonly
                    [placeholder]="col.filter.label || 'Seleziona'" [formControl]="col.filter.control"
                    style="cursor: pointer" />
                  <!-- <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle> -->
                  <mat-datepicker #dp></mat-datepicker>
                </ng-container>

                <mat-icon matSuffix fontSet="fal" fontIcon="fa-times" class="reset-input-icon"
                  *ngIf="col.filter.control.value" (click)="onResetFilter(col.filter.control)">
                </mat-icon>
              </mat-form-field>

              <!-- TYPE === 'autocomplete' -->
              <ng-container class="filter" *ngIf="col.filter && col.filter.type === 'autocomplete'">
                <app-autocomplete-type [filter]="col.filter" [onResetFilter]="onResetFilter.bind(this)">
                </app-autocomplete-type>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
          <div class="element-detail" [@detailExpand]="row == expandedRow ? 'expanded' : 'collapsed'">
            <table class="changes-table">
              <tbody>
                <tr>
                  <td></td>
                  <td><strong>ATTUALE</strong></td>
                  <td><strong>PRECEDENTE</strong></td>
                </tr>
                <tr>
                  <td><strong>Tipologia</strong></td>
                  <td>{{ getProductType(row.newValue?.type) }}</td>
                  <td>{{ getProductType(row.previousValue?.type) }}</td>
                </tr>
                <tr>
                  <td><strong>Seriale</strong></td>
                  <td>{{ row.newValue?.serialNumber }}</td>
                  <td>{{ row.previousValue?.serialNumber }}</td>
                </tr>
                <tr>
                  <td><strong>N° Telefono</strong></td>
                  <td>{{ row.newValue?.simPhone?.number || 'vuoto' }}</td>
                  <td>{{ row.previousValue?.simPhone?.number || 'vuoto' }}</td>
                </tr>
                <tr>
                  <td><strong>PIN</strong></td>
                  <td>{{ row.newValue?.simPin || 'vuoto' }}</td>
                  <td>{{ row.previousValue?.simPin || 'vuoto' }}</td>
                </tr>
                <tr>
                  <td><strong>PUK</strong></td>
                  <td>{{ row.newValue?.simPuk || 'vuoto' }}</td>
                  <td>{{ row.previousValue?.simPuk || 'vuoto' }}</td>
                </tr>
                <tr>
                  <td><strong>PWD Router</strong></td>
                  <td>{{ row.newValue?.routerPassword || 'vuoto' }}</td>
                  <td>{{ row.previousValue?.routerPassword || 'vuoto' }}</td>
                </tr>
                <tr>
                  <td><strong>PWD Wifi</strong></td>
                  <td>{{ row.newValue?.wifiPassword || 'vuoto' }}</td>
                  <td>{{ row.previousValue?.wifiPassword || 'vuoto' }}</td>
                </tr>
                <tr>
                  <td><strong>notes</strong></td>
                  <td>{{ row.newValue?.notes || 'vuoto' }}</td>
                  <td>{{ row.previousValue?.notes || 'vuoto' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" [class]="config.rowClass ? config.rowClass(row) : ''"
        [class.expanded-row]="expandedRow === row" (click)="expandedRow = expandedRow === row ? null : row">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

      <ng-container *ngIf="showFilters">
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </ng-container>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length + 1">Nessun dato presente</td>
      </tr>
    </table>
  </div> <!-- /.table-responsive -->

  <mat-paginator [length]="config.dataSource.totalCount$ | async"
    [pageSizeOptions]="config?.pageSizeOptions || configService.pageSizeOptions"
    [pageSize]="config?.pageSize || configService.defaultPageSize" showFirstLastButtons></mat-paginator>
</div>
