// Core packages
import { Component, Input } from '@angular/core';

// Third party packages

// Custom packages
import { PageConfig } from '../../interfaces/pageConfig.interface';

/**
 * Script start
 */
@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent {
  @Input('config') config!: PageConfig;

  constructor() {}
}
