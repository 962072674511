// Core packages
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

// Third party packages
import { Observable, Subscription } from 'rxjs';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ToastrService } from 'ngx-toastr';
import { finalize, take } from 'rxjs/operators';

// Custom packages
import { HelperService } from 'src/app/shared/services/helper.service';
import { PageService } from 'src/app/shared/services/loading.service';
import { TranslationsService } from '../../translations.service';
import { Translation } from 'src/app/shared/models/translation/translation.model';
import Response from 'src/app/shared/interfaces/response.interface';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { LanguagesService } from 'src/app/modules/languages/languages.service';
import ILanguage from 'src/app/shared/models/language/language.interface';
import ListApiResponse from 'src/app/shared/interfaces/listApi.response.interface';
import { ConfigService } from 'src/app/shared/services/config.service';
import { AuthService } from 'src/app/modules/auth/auth.service';

/**
 * Script start
 */
@Component({
  selector: 'app-translation-detail',
  templateUrl: './translation-detail.page.html',
  styleUrls: ['./translation-detail.page.scss'],
})
export class TranslationDetailComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public translation: Translation = new Translation();
  // BEGIN - Form
  form: UntypedFormGroup = new UntypedFormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};
  formBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Aggiorna',
    spinnerSize: 19,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
    // buttonIcon: {
    //   fontSet: 'fal',
    //   fontIcon: 'fa-save',
    //   inline: true,
    // },
  };
  // END - Form

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private pageService: PageService,
    private translationService: TranslationsService,
    private helperService: HelperService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
    private languagesService: LanguagesService,
    public configService: ConfigService,
    public authService: AuthService,
  ) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    this.pageService.loading$.next(true);
    const itemId = this.activatedRoute.snapshot.params.transId;
    this.subscriptions.push(
      this.translationService
        .get(itemId)
        .pipe(finalize(() => this.pageService.loading$.next(false)))
        .subscribe(
          (res: Response) => {
            if (!res.status) {
              // Something bad happened
              this.router.navigate(['translations']);
            }

            // Set data
            this.translation.setData(res?.data);

            // Init edit form
            this.initForm();
          },
          (err: Response) => {
            this.helperService.handleError(err);
            this.router.navigate(['errors', '404']);
          },
        ),
    );
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Init form
   *
   * @since 1.0.0
   */
  initForm(): void {
    // Set model
    this.model = this.translation.getData();

    // Set fields
    this.fields = [
      ...this.fields,
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'autocomplete',
            key: `lang`,
            templateOptions: {
              label: 'Lingua',
              placeholder: 'Cerca...',
              required: true,
              labelExtractor: (obj: ILanguage): string => {
                return `${obj.isoName} (${obj.iso6391.toUpperCase()})`;
              },
              valueExtractor: (obj: ILanguage): string => {
                return obj._id;
              },
              multiple: false,
              search$: (
                start?: number,
                limit?: number,
                sort?: string,
                sortVersus?: string,
                search?: any,
              ): Observable<ListApiResponse> =>
                this.languagesService.getList(
                  start,
                  limit,
                  sort,
                  sortVersus,
                  search,
                ),
              getAll: true,
              clearIcon: 'fa-trash',
              notFoundText: 'Non trovato',
              loadingText: 'Caricamento...',
            },
          },
          {
            className: 'col-12 col-md-6',
            type: 'select',
            key: 'section',
            templateOptions: {
              label: 'Sezione',
              placeholder: 'Seleziona',
              required: true,
              disabled: true,
              options: [
                {
                  value: 'fe',
                  label: 'Front-end',
                },
                {
                  value: 'be',
                  label: 'Back-end',
                },
              ],
            },
          },
          {
            className: 'col-12 col-md-6',
            type: 'select',
            key: 'confirmed',
            templateOptions: {
              label: 'Confermata',
              placeholder: 'Seleziona',
              required: true,
              options: [
                {
                  value: true,
                  label: 'Si',
                },
                {
                  value: false,
                  label: 'No',
                },
              ],
            },
          },
          {
            className: 'col-12',
            type: 'input',
            key: 'key',
            templateOptions: {
              label: 'Chiave',
              placeholder: 'Inserisci qui...',
              required: true,
              type: 'string',
            },
          },
          {
            className: 'col-12',
            type: 'textarea',
            key: 'value',
            templateOptions: {
              label: 'Valore',
              placeholder: 'Inserisci qui...',
              required: true,
              type: 'string',
              rows: 4,
            },
          },
        ],
      },
    ];
  }

  /**
   * Handle form submit
   *
   * @since 1.0.0
   */
  onFormSubmit(model: any): void {
    // Prevent invalid form submit
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsTouched();
      });
      this.toastrService.error(
        'Il modulo contiene degli errori. Controlla e riprova',
      );
      return;
    }

    // Activate submit button loading status
    this.formBtnOptions = { ...this.formBtnOptions, active: true };

    // Send data to BE
    this.subscriptions.push(
      this.translationService
        .update(this.translation.getId() as string, model)
        .pipe(finalize(() => (this.formBtnOptions = { ...this.formBtnOptions, active: false })))
        .subscribe(
          (res: Response) => {
            if (!res.status) {
              const title = 'Errore';
              let message =
                res.message ||
                "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
              this.toastrService.error(message, title);
              return;
            }

            this.toastrService.success(res.message);
            // this.router.navigate(['translations']);
          },
          (err: Response) => {
            // Choose one of the following error handling method.
            // 1) First one show a message right under the form fields
            //    (if the form is properly setted)
            // 2) Second one is used to show a mat-error under each field
            //    in the dynamic form (is meant to be used only in dynamicForm)
            // 3) Third one show toastr notifications for each error
            // 4) Fourth one is used to show mat-error under each field
            //    in the ngx-formly form (is mean to be used only with ngx-formly)
            // this.helperService.handleFormError(form, err);
            // this.helperService.handleDynamicFormError(form, err);
            // this.helperService.handleError(err);
            this.helperService.handleFormlyError(this.form, err);
          },
        ),
    );
  }

  /**
   * Handle delete
   *
   * @since 1.0.0
   */
  onDelete(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Sei sicuro?',
        message:
          "Procedendo eliminerai la traduzione. L'operazione è irreversibile",
        btnOkText: 'Si, sono sicuro',
        btnCancelText: 'Annulla',
      },
      width: '500px',
      disableClose: true,
    });
    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (!res) {
          return;
        }

        // Start loading
        this.pageService.loading$.next(true);

        // Ok, so user really want to delete.
        // Let's do it!
        this.subscriptions.push(
          this.translationService
            .delete(this.translation.getId() as string)
            .pipe(finalize(() => this.pageService.loading$.next(false)))
            .subscribe(
              (res: Response) => {
                if (!res.status) {
                  const title = 'Errore';
                  let message =
                    res.message ||
                    "Si è verificato un errore imprevisto. Contatta l'assistenza per supporto tecnico";
                  this.toastrService.error(message, title);
                  return;
                }

                this.toastrService.success(res.message);
                this.router.navigate(['translations']);
              },
              (err: any) => {
                // Choose one of the following error handling
                // method. The first one shows a message right
                // under the form fields (if the form is properly
                // setted), the second one shows toastr
                // notifications for each error
                this.helperService.handleError(err);
              },
            ),
        );
      });
  }
}
