// Core packages
import { Component } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/auth.service';
import IUser from 'src/app/shared/models/user/user.interface';

// Third party packages

// Custom packages
import { ThemeService } from 'src/app/shared/services/theme.service';

/**
 * Script start
 */
@Component({
  selector: 'app-wrapper-app',
  templateUrl: './app-wrapper.component.html',
  styleUrls: ['./app-wrapper.component.scss'],
})
export class AppWrapperComponent {
  private subscriptions: Subscription[] = [];
  isOnline = true;
  loggedUser: IUser | undefined;
  online$: Observable<Event> = new Observable();
  offline$: Observable<Event> = new Observable();

  /**
   * Class constructor
   */
  constructor(
    private themeService: ThemeService,
    private authService: AuthService,
  ) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    this.subscriptions.push(
      this.authService.loggedUser$.subscribe((res?: IUser) => {
        this.updateBodyClasses(res);
        this.loggedUser = res;
      }),
    );

    this.online$ = fromEvent(window, 'online');
    const onlineSubscription = this.online$.subscribe((res) => {
      this.isOnline = true;
    });
    this.subscriptions.push(onlineSubscription);

    this.offline$ = fromEvent(window, 'offline');
    const offlineSubscription = this.offline$.subscribe((res) => {
      this.isOnline = false;
    });
    this.subscriptions.push(offlineSubscription);
  }

  /**
   * Update body classes depenging on logged user roles
   * First of all remove al classes for previous role (if present)
   * and then set a class for each current role
   *
   * @since 1.0.0
   */
  updateBodyClasses(newUserData?: IUser): void {
    if (this.loggedUser) {
      this.themeService.removeClassToBody(
        `logged-${encodeURI(this.loggedUser.role.toLowerCase())}`,
      );
    }
    if (newUserData) {
      this.themeService.setClassToBody(
        `logged-${encodeURI(newUserData.role.toLowerCase())}`,
      );
    }
  }
}
