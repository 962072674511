// Core packages
import { Component } from '@angular/core';
import { Location } from '@angular/common';

// Third party packages

// Custom packages
import { ConfigService } from 'src/app/shared/services/config.service';

/**
 * Script start
 */
@Component({
  selector: 'app-error-404',
  templateUrl: './error-404.component.html',
  styleUrls: ['./error-404.component.scss'],
})
export class Error404Component {
  /**
   * Class constructor
   */
  constructor(
    private location: Location,
    public configService: ConfigService,
  ) {}

  /**
   * Go back to last visited page
   *
   * @since 1.0.0
   */
  backClicked(): void {
    // Once user clicked on "Back" button, then go back to previous page
    this.location.back();
  }
}
