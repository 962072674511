<app-page>
  <div class="container-fluid">
    <div class="row">

      <div class="col-12">
        <div class="breadcrumb-wrapper">
          <div class="title">
            Dettaglio
          </div>
          <ul class="breadcrumb">
            <li>
              <mat-icon fontSet="fal" fontIcon="fa-home"></mat-icon>
            </li>
            <li>
              <a routerLink="/users">
                Utenti
              </a>
            </li>
            <li>
              {{ user.get('firstName') }} {{ user.get('lastName') }}
            </li>
          </ul> <!-- /.breadcrumb -->
        </div> <!-- /.breadcrumb-wrapper -->
      </div> <!-- /.col -->

      <div class="col-12">
        <mat-card class="mat-elevation-z shadow">
          <div class="card-header">
            <div class="col-9 d-flex align-items-center">
              <mat-card-title>
                Informazioni <span class="d-none d-md-inline-block">utente</span>
              </mat-card-title>
            </div> <!-- /.col -->
            <div class="col-3 d-flex justify-content-end">
              <ng-container
                *ngIf="configService.settings.deletePermittedRoles.includes(authService.loggedUser$?.value?.role)">
                <button mat-button [matMenuTriggerFor]="cardMenu">
                  <mat-icon fontSet="fal" fontIcon="fa-ellipsis-v-alt"></mat-icon>
                </button>
                <mat-menu #cardMenu="matMenu" xPosition="before">
                  <button mat-menu-item (click)="onDelete()">
                    <mat-icon fontSet="fal" fontIcon="fa-trash"></mat-icon>
                    Elimina
                  </button>
                </mat-menu>
              </ng-container>
            </div> <!-- /.col-12 -->
          </div> <!-- /.row -->
          <mat-card-content class="pt-0">
            <div class="form-wrapper mt-3">
              <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
                <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
                <mat-spinner-button role="submit" [options]="formBtnOptions" class="btn-elevate" *ngIf="!readOnlyMode">
                </mat-spinner-button>
              </form>
            </div> <!-- /.form-wrapper -->
          </mat-card-content>
        </mat-card>
      </div> <!-- /.col -->

    </div> <!-- /.row -->
  </div> <!-- /.container -->
</app-page>
