// Core components
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  AfterViewInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// Third party components
import { FieldType } from '@ngx-formly/material';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { finalize, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

// Custom components
import { HelperService } from 'src/app/shared/services/helper.service';
import { LightboxDialogComponent } from '../../dialogs/lightbox-dialog/lightbox-dialog.component';
import Response from 'src/app/shared/interfaces/response.interface';
import IFile from 'src/app/shared/interfaces/file.interface';
import { FileService } from 'src/app/shared/services/file.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { environment } from 'src/environments/environment';

/**
 * Script start
 */
@Component({
  selector: 'app-formly-images-uploader-type',
  templateUrl: './images-uploader-type.component.html',
  styleUrls: ['./images-uploader-type.component.scss'],
})
export class ImagesUploaderTypeComponent
  extends FieldType
  implements OnInit, OnDestroy, AfterViewInit {
  private subscriptions: Subscription[] = [];
  triggerFileUpload = false;
  triggerFileReset = false;
  importFormSubmitBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Importa',
    spinnerSize: 19,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
    customClass: 'mt-2',
  };
  uploadedFiles: IFile[] = [];
  filesToUpload: any[] = [];
  previousValue: any;

  constructor(
    private helperService: HelperService,
    private toastrService: ToastrService,
    public dialog: MatDialog,
    private fileService: FileService,
  ) {
    super();
  }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    super.ngOnInit();

    // Set default tempateOptions
    if (typeof this.to.allowedFilesNumber === 'undefined') {
      this.to.allowedFilesNumber = 1;
    }
    if (typeof this.to.allowedFilesExtensions === 'undefined') {
      this.to.allowedFilesExtensions = '*';
    }
    if (typeof this.to.defaultValue === 'undefined') {
      this.to.defaultValue = [];
    }

    // Set initial values
    this.filesToUpload = this.to.defaultValue;

    if (
      this.formControl.value &&
      this.previousValue !== this.formControl.value
    ) {
      this.previousValue = this.formControl.value;
      this.initFiles(this.formControl.value);
    }

    // Handle form value changes (these are the uploaded files)
    this.subscriptions.push(
      this.formControl.valueChanges.subscribe((res) => {
        if (res === this.previousValue) {
          return;
        }
        this.previousValue = res;
        this.initFiles(res);
      }),
    );
  }

  /**
   * After view init logic
   *
   * @since 1.0.0
   */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Handle event "uploadFiles" emitted from file-uploader component
   *
   * @since 1.0.0
   *
   * @param files Files to upload
   * @returns undefined
   */
  onUploadFiles(files: NgxFileDropEntry[]): void {
    if (this.options?.formState.disabled) {
      console.warn('Block upload because field is disabled'); //@todo: translate
      return;
    } else {
      this.importFormSubmitBtnOptions.disabled = false;
    }

    // Check total file number (if too much don't even try to upload anything)
    const currentFilesNumber =
      this.uploadedFiles.length + this.filesToUpload.length;
    if (currentFilesNumber >= this.to.allowedFilesNumber) {
      const title = 'Attenzione';
      const message = 'Hai raggiunto il numero massimo di file';
      this.toastrService.warning(message, title);
      return;
    }

    const currentFilesNames = [
      ...this.uploadedFiles,
      ...this.filesToUpload,
    ].map((file) => file.originalName);

    this.importFormSubmitBtnOptions.active = true;

    // You could upload it like this:
    const formData = new FormData();

    let counter = 1;
    for (const givenFile of files) {
      const fileEntry = givenFile.fileEntry as FileSystemFileEntry;
      if (currentFilesNames.includes(fileEntry.name)) {
        const title = 'Attenzione';
        const message = 'Il file è già presente';
        this.toastrService.warning(message, title);
        return;
      }
      fileEntry.file((file: File) => {
        const name =
          this.to.allowedFilesNumber > 1 ? `${this.key}[]` : `${this.key}`;
        formData.append(name, file, givenFile.relativePath);
        counter++;
        if (counter === files.length + 1) {
          this.subscriptions.push(
            (this.fileService.uploadTempFile(formData) as Observable<any>)
              .pipe(
                finalize(
                  () => (this.importFormSubmitBtnOptions.active = false),
                ),
              )
              .subscribe(
                (res: Response) => {
                  // Accept all-and-only last (this.to.allowedFilesNumber)
                  // files (this is needed! do not remove!)
                  let files = [];
                  if (res.status) {
                    files = res.data.slice(-this.to.allowedFilesNumber);
                  }

                  // If available, call the callback
                  if (typeof this.to.callback === 'function') {
                    this.to.callback(res, this.model);
                  }
                  // Handle res status
                  if (res.status) {
                    this.triggerFileReset = !this.triggerFileReset; // Reset drop-zone area
                    this.updateUpdatedFiles(files);
                    return;
                  }
                  if (res.message) {
                    const title = 'Attenzione';
                    this.toastrService.warning(res.message, title);
                    return;
                  }
                  const title = 'Attenzione';
                  const message = 'Si è verificato un errore imprevisto';
                  this.toastrService.warning(message, title);
                },
                (err: Response) => {
                  // Choose one of the following error handling
                  // method. The first one show a message right
                  // under the form fields (if the form is properly
                  // setted), the second one show toastr
                  // notifications for each error
                  // this.helperService.handleFormError(this.form, err);
                  this.helperService.handleError(err);
                },
              ),
          );
        }
      });
    }
  }

  /**
   * Init element uploaded files
   *
   * @since 1.0.0
   */
  initFiles(files: any[]): void {
    const apiHost = environment.apiHost;

    // Update image previews list
    this.uploadedFiles = [];

    // More files
    if (Array.isArray(files) && files.length) {
      files.forEach((file: any) => {
        const path = file.path;
        if (path.substring(0, apiHost.length) !== apiHost) {
          const newPath = `${apiHost}${path}`;
          file.path = newPath;
        }
        this.uploadedFiles.push(file);
      });
      return;
    }

    // Just one file
    if (!Array.isArray(files) && files) {
      [files].forEach((file: any) => {
        const path = file.path;
        if (path.substring(0, apiHost.length) !== apiHost) {
          const newPath = `${apiHost}${path}`;
          file.path = newPath;
        }
        this.uploadedFiles.push(file);
      });
      return;
    }

    this.uploadedFiles = [];
  }

  /**
   * Current files to upload
   *
   * @since 1.0.0
   */
  updateUpdatedFiles(filesArray: any[]): void {
    let files = filesArray;

    const apiHost = environment.apiHost;
    // Update image previews list
    if (Array.isArray(files) && files.length) {
      files.forEach((file: any) => {
        const path = file.path;
        const newPath = `${apiHost}${path}`;
        file.path = newPath;
        // }
        if (path.includes('temp')) {
          this.filesToUpload.push(file);
          // Prevent visualization of more previews than allowed
          this.filesToUpload = this.filesToUpload.slice(
            -this.to.allowedFilesNumber,
          );
        }
      });
    } else {
      this.filesToUpload = [];
    }

    this.value = this.filesToUpload;
  }

  /**
   * Remove given item from:
   * 1) File previews
   * 2) Field's value
   *
   * @since 1.0.0
   */
  onRemove(file: any): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Sei sicuro?',
        message:
          'Procedendo rimuoverai il file. Una volta rimosso, ricordati di salvare',
        btnOkText: 'Si, sono sicuro',
        btnCancelText: 'Annulla',
      },
      width: '500px',
      disableClose: true,
    });
    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (!res) {
          return;
        }

        // Remove file from previews
        this.filesToUpload = this.filesToUpload.filter(
          (item: any) => item._id !== file._id,
        );

        // Remove file from field value
        if (
          typeof file._id !== 'undefined' &&
          this.model[this.key as string] === file._id
        ) {
          this.model[this.key as string] = null;
        }
        if (Array.isArray(this.model[this.key as string])) {
          this.model[this.key as string] = this.model[
            this.key as string
          ].filter((item: any) => item._id !== file._id);
        }
      });
  }

  /**
   * Remove given item from uploaded files
   *
   * @since 1.0.0
   */
  onRemoveUploadedFile(file: any): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Sei sicuro?',
        message:
          'Procedendo rimuoverai il file. Una volta rimosso, ricordati di salvare',
        btnOkText: 'Si, sono sicuro',
        btnCancelText: 'Annulla',
      },
      width: '500px',
      disableClose: true,
    });
    // Subscribe to dialog result (only for 1 emit thanks to "take()")
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((res: boolean) => {
        if (!res) {
          return;
        }

        // Remove file from previews
        this.uploadedFiles = this.uploadedFiles.filter(
          (item: any) => item._id !== file._id,
        );

        this.value = this.uploadedFiles;
      });

    // // Remove file from model/form
    // if (
    //   typeof file._id !== 'undefined' &&
    //   this.model[this.key as string] === file._id
    // ) {
    //   this.model[this.key as string] = null;
    // }
    // if (Array.isArray(this.model[this.key as string])) {
    //   this.model[this.key as string] = this.model[this.key as string].filter(
    //     (item: any) => item._id !== file._id,
    //   );
    // }

    // if (Array.isArray(this.value)) {
    //   this.value.splice(this.value.indexOf(file));
    // }
  }

  /**
   * Handle click on a image preview and opens it in a dialog
   *
   * @since 1.0.0
   */
  onOpenImage(file: any): void {
    this.dialog.open(LightboxDialogComponent, {
      data: {
        url: file.path,
      },
      panelClass: 'lightbox-dialog',
    });
  }

  /**
   * Check if given file is already in a pending upload list
   */
  fileHasToBeUploaded(file: any): boolean {
    const found = this.filesToUpload.find((el) => el._id === file._id);
    return found;
  }

  thereAreUploadedFiles(): boolean {
    const filesAlreadyUpoaded = this.uploadedFiles.filter((file: any) => {
      return !this.filesToUpload.find((f) => f._id !== file._id);
    });
    return filesAlreadyUpoaded.length ? true : false;
  }
}
